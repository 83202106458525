import React, { useState } from 'react';
import { X, Check, Clock, MapPin, User, CreditCard, Train, ArrowRight, Users } from 'lucide-react';
import { getStationName } from '../utils/stations';
import { formatDateTime } from '../utils/dateUtils';
import { toast } from 'react-hot-toast';
import MD5 from 'crypto-js/md5';

const BookingConfirmation = ({ booking, onClose, onCancel, t }) => {
  const [cancelling, setCancelling] = useState(false);
  const [cancelError, setCancelError] = useState(null);
  const [cancelSuccess, setCancelSuccess] = useState(false);

  if (!booking) return null;

  const ticket = booking.tickets?.[0] || booking;
  
  // Получаем данные о пассажире из разных возможных источников
  const passengerName = (() => {
    // Проверяем данные в разных местах
    const firstName = booking.firstName || 
                     ticket.firstName || 
                     booking.passengersInfo?.passengers?.[0]?.firstName ||
                     ticket.passengersInfo?.passengers?.[0]?.firstName;
    
    // Проверяем все возможные источники фамилии
    const lastName = booking.surname || 
                    booking.lastName || 
                    ticket.surname ||
                    ticket.lastName || 
                    booking.passengersInfo?.passengers?.[0]?.surname ||
                    booking.passengersInfo?.passengers?.[0]?.lastName ||
                    ticket.passengersInfo?.passengers?.[0]?.surname ||
                    ticket.passengersInfo?.passengers?.[0]?.lastName;

    // Добавляем подробный отладочный вывод
    console.log('Passenger name resolution:', { 
      firstName, 
      lastName,
      sources: {
        booking: {
          firstName: booking.firstName,
          lastName: booking.lastName,
          surname: booking.surname,
          passengerInfo: booking.passengersInfo?.passengers?.[0]
        },
        ticket: {
          firstName: ticket.firstName,
          lastName: ticket.lastName,
          surname: ticket.surname,
          passengerInfo: ticket.passengersInfo?.passengers?.[0]
        }
      }
    });

    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return 'Не указано';
  })();

  // Получаем данные о станциях с полными названиями
  const fromStation = (() => {
    const station = ticket.departureStationName || 
                   ticket.departureStation || 
                   booking.departureCityName ||
                   'Не указано';
    // Преобразуем сокращенные названия
    if (station === 'MS') return 'Брно';
    if (station === 'Sta.') return 'Бржецлав';
    return station;
  })();

  const toStation = (() => {
    const station = ticket.arrivalStationName || 
                   ticket.arrivalStation || 
                   booking.arrivalCityName ||
                   'Не указано';
    // Преобразуем сокращенные названия
    if (station === 'MS') return 'Брно';
    if (station === 'Sta.') return 'Бржецлав';
    return station;
  })();

  // Получаем данные о месте
  const seatNumber = ticket.seatNumber || 
                    booking.seatNumbers?.[0] || 
                    'Не указано';
  
  const vehicleNumber = ticket.vehicleNumber || 
                       booking.vehicleNumbers?.[0] || 
                       'Не указано';

  // Добавляем отображение стоимости
  const price = booking.price || ticket.price || 0;
  const currency = (booking.currency || ticket.currency || 'EUR').toUpperCase();

  // Обновляем функцию форматирования времени в пути
  const formatTravelTime = (travelTime) => {
    if (!travelTime) return '';
    
    // Сначала убираем все пробелы вокруг 'h'
    let formatted = travelTime.replace(/\s*h\s*/g, 'h');
    // Затем убираем дублирование 'h'
    formatted = formatted.replace(/h+/g, 'h');
    // Добавляем пробел перед 'h'
    formatted = formatted.replace(/h/, ' h');
    // Убираем лишние пробелы
    return formatted.trim();
  };

  // Получаем время в пути
  const travelTime = formatTravelTime(ticket.travelTime || booking.travelTime);

  // Форматирование времени
  const formatTime = (dateString) => {
    try {
      return new Date(dateString).toLocaleTimeString('ru-RU', {
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Error formatting time:', error);
      return '';
    }
  };

  // Форматирование даты
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  // Получаем email пассажира из разных возможных источников
  const passengerEmail = booking.email || 
                        ticket.email ||
                        booking.passengersInfo?.passengers?.[0]?.email ||
                        ticket.passengersInfo?.passengers?.[0]?.email;

  // Печать билета
  const handlePrintTicket = async () => {
    try {
      const cleanToken = booking.token.trim().replace(/^Bearer\s+/i, '');
      const authToken = `Bearer ${cleanToken}`;

      console.log('Printing ticket:', {
        id: ticket.id,
        accountCode: ticket.accountCode,
        tokenPreview: cleanToken.substring(0, 20) + '...'
      });

      const response = await fetch(
        `/api/tickets/${ticket.accountCode}/${ticket.id}/print`,
        {
          headers: {
            'Authorization': authToken,
            'Accept': 'text/html'
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to get ticket print data');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const printWindow = window.open(url, '_blank');
      
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 100);
        };
      } else {
        throw new Error('Popup blocked. Please allow popups and try again.');
      }
    } catch (error) {
      console.error('Print error:', error);
      alert(t.printError || 'Failed to print ticket');
    }
  };

  // Показать QR код
  const handleShowQR = async () => {
    try {
      // Поддержка старого и нового формата
      if (ticket.accountCode) {
        // Старый формат
        const cleanToken = booking.token.trim().replace(/^Bearer\s+/i, '');
        const authToken = `Bearer ${cleanToken}`;

        console.log('Getting QR code (legacy):', {
          id: ticket.id,
          accountCode: ticket.accountCode,
          tokenPreview: cleanToken.substring(0, 20) + '...'
        });

        const response = await fetch(
          `/api/tickets/${ticket.accountCode}/${ticket.id}/qrcode/png`,
          {
            headers: {
              'Authorization': authToken,
              'Accept': 'image/png'
            }
          }
        );

        if (!response.ok) {
          throw new Error('Failed to get QR code');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      } else {
        // Новый формат с полными данными билета
        const ticketData = {
          ticketId: ticket.id,
          passenger: {
            firstName: booking.firstName || ticket.firstName,
            lastName: booking.lastName || ticket.lastName || booking.surname || ticket.surname,
          },
          route: {
            from: fromStation,
            to: toStation,
            departureTime: ticket.departureTime,
            arrivalTime: ticket.arrivalTime,
            travelTime: travelTime
          },
          seat: {
            number: seatNumber,
            vehicleNumber: vehicleNumber
          },
          price: {
            amount: price,
            currency: currency
          }
        };

        const ticketDataString = JSON.stringify(ticketData);
        const qrDataUrl = `/api/tickets/qr?data=${encodeURIComponent(ticketDataString)}`;

        const response = await fetch(qrDataUrl, {
          headers: {
            'Accept': 'image/png'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to get QR code');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        
        const qrWindow = window.open('', '_blank');
        if (qrWindow) {
          qrWindow.document.write(`
            <html>
              <head>
                <title>Билет #${ticket.id}</title>
                <style>
                  body {
                    font-family: Arial, sans-serif;
                    margin: 20px;
                    text-align: center;
                  }
                  .ticket-info {
                    margin: 20px auto;
                    max-width: 600px;
                    text-align: left;
                  }
                  .qr-code {
                    margin: 20px auto;
                  }
                  .qr-code img {
                    max-width: 300px;
                  }
                </style>
              </head>
              <body>
                <h1>Билет #${ticket.id}</h1>
                <div class="ticket-info">
                  <h2>Пассажир</h2>
                  <p>${passengerName}</p>
                  <h2>Маршрут</h2>
                  <p>Откуда: ${fromStation}</p>
                  <p>Куда: ${toStation}</p>
                  <p>Отправление: ${formatDateTime(ticket.departureTime)}</p>
                  <p>Прибытие: ${formatDateTime(ticket.arrivalTime)}</p>
                  ${travelTime ? `<p>Время в пути: ${travelTime}</p>` : ''}
                  <h2>Место</h2>
                  <p>Место: ${seatNumber}</p>
                  <p>Вагон №: ${vehicleNumber}</p>
                  <h2>Стоимость</h2>
                  <p>${price} ${currency}</p>
                </div>
                <div class="qr-code">
                  <img src="${url}" alt="QR Code" />
                </div>
              </body>
            </html>
          `);
          qrWindow.document.close();
        }
      }
    } catch (error) {
      console.error('QR error:', error);
      alert(t.qrError || 'Failed to show QR code');
    }
  };

  // Отмена билета
  const handleCancelTicket = async () => {
    try {
      setCancelling(true);
      setCancelError(null);

      // Получаем данные из объекта бронирования
      const ticketId = ticket.id || booking.booking_id;
      const accountCode = ticket.accountCode || booking.account_code;
      const token = booking.token || booking.booking_token;

      // Проверяем наличие необходимых данных
      if (!ticketId || !accountCode || !token) {
        throw new Error('Недостаточно данных для отмены билета');
      }

      // Получаем токен авторизации
      const authToken = token.startsWith('Bearer ') 
        ? token 
        : `Bearer ${token}`;

      // Отправляем запрос на отмену
      const response = await fetch(`/api/tickets/${accountCode}/${ticketId}/cancel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken
        },
        body: JSON.stringify({
          controlHash: ticket.controlHash
        })
      });

      // Обрабатываем ответ
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Ошибка при отмене билета');
      }

      // Обновляем состояние
      setCancelSuccess(true);
      
      // Обработка возврата средств
      if (booking.paymentId) {
        await handleRefund();
      }

    } catch (error) {
      console.error('Cancel error:', error);
      setCancelError(error.message || 'Не удалось отменить билет');
    } finally {
      setCancelling(false);
    }
  };

  // Заменяем функцию generateControlHash на более простую версию для браузера
  const generateControlHash = (ticketId) => {
    const timestamp = Date.now();
    const data = `${ticketId}-${timestamp}`;
    return MD5(data).toString().toUpperCase();
  };

  // Обработка возврата средств
  const handleRefund = async () => {
    try {
      const response = await fetch('/api/payments/refund', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          paymentId: booking.paymentId,
          amount: booking.price * 100, // Stripe работает с центами
          currency: booking.currency.toLowerCase(),
          reason: 'requested_by_customer',
          bookingId: booking.id
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Ошибка при возврате средств');
      }

      console.log('Refund processed:', data);
    } catch (error) {
      console.error('Refund error:', error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50">
      <div className="min-h-screen px-4 flex items-center justify-center">
        <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
          <div className="p-6">
            <div className="flex justify-between items-start mb-6">
              <h2 className="text-2xl font-bold text-gray-900">
                {t.bookingConfirmation || 'Подтверждение бронирования'}
              </h2>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <X size={24} />
              </button>
            </div>

            {/* Показываем уведомление об успешной отмене */}
            {cancelSuccess && (
              <div className="bg-green-100 border-l-4 border-green-500 p-4 mb-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <Check className="h-5 w-5 text-green-500" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-green-700">
                      Билет успешно отменен
                    </p>
                  </div>
                </div>
              </div>
            )}
            
            {/* Показываем ошибку отмены */}
            {cancelError && (
              <div className="bg-red-100 border-l-4 border-red-500 p-4 mb-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <X className="h-5 w-5 text-red-500" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {cancelError}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* Информация о билете */}
            <div className="space-y-6">
              {/* Пассажир */}
              <div className="border-b pb-4">
                <h3 className="font-semibold mb-2">{t.passenger || 'Пассажир'}</h3>
                <p>{passengerName}</p>
              </div>

              {/* Маршрут */}
              <div className="border-b pb-4">
                <h3 className="font-semibold mb-2">{t.route || 'Маршрут'}</h3>
                <div className="space-y-2">
                  <p><span className="text-gray-600">{t.from || 'Откуда'}:</span> {fromStation}</p>
                  <p><span className="text-gray-600">{t.to || 'Куда'}:</span> {toStation}</p>
                  <p><span className="text-gray-600">{t.departure || 'Отправление'}:</span> {formatDateTime(ticket.departureTime)}</p>
                  <p><span className="text-gray-600">{t.arrival || 'Прибытие'}:</span> {formatDateTime(ticket.arrivalTime)}</p>
                  {travelTime && (
                    <div className="flex items-center text-gray-600">
                      <Clock className="text-[#9a55f4] mr-2" size={16} />
                      <span>{travelTime}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* Детали места */}
              <div className="border-b pb-4">
                <h3 className="font-semibold mb-2">{t.seatDetails || 'Детали места'}</h3>
                <div className="space-y-2">
                  <p><span className="text-gray-600">{t.seat || 'Место'}:</span> {seatNumber}</p>
                  <p><span className="text-gray-600">{t.vehicle || 'Вагон'} №:</span> {vehicleNumber}</p>
                </div>
              </div>

              {/* Добавляем блок со стоимостью */}
              <div className="border-b pb-4">
                <h3 className="font-semibold mb-2">{t.price || 'Стоимость'}</h3>
                <p>{price} {currency}</p>
              </div>
            </div>

            {/* Кнопки действий */}
            <div className="mt-6 space-y-3">
              {ticket.customerActions?.print && (
                <button
                  onClick={handlePrintTicket}
                  className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  {t.printTicket}
                </button>
              )}

              {ticket.customerActions?.qr && (
                <button
                  onClick={handleShowQR}
                  className="w-full py-2 px-4 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  {t.showQR}
                </button>
              )}

              {!booking.cancelled && (
                <button
                  onClick={handleCancelTicket}
                  disabled={cancelling}
                  className="w-full py-2 px-4 bg-red-100 text-red-700 rounded-md hover:bg-red-200 disabled:opacity-50"
                >
                  {cancelling ? t.cancelling : t.cancelBooking}
                </button>
              )}

              <button
                onClick={onClose}
                className="w-full py-2 px-4 bg-[#9a55f4] text-white rounded-md hover:bg-[#8645e0]"
              >
                {t.close}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;