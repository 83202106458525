import React from 'react';
import { Clock, MapPin, DollarSign, Users, Train, Bus } from 'lucide-react';

const RouteDetails = ({ route, t }) => {
  // Добавляем маппинг станций
  const stationNames = {
    '3088864001': 'Прага',
    '3088864002': 'Острава',
    '3088864003': 'Брно',
    '3088864004': 'Пльзень',
    // Добавьте другие станции по необходимости
  };

  const getStationName = (stationId) => {
    return stationNames[stationId] || stationId;
  };

  // Форматирование времени
  const formatTime = (dateString) => {
    try {
      return new Date(dateString).toLocaleTimeString('ru-RU', {
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch {
      return dateString;
    }
  };

  // Добавляем функцию форматирования длительности
  const formatDuration = (duration) => {
    if (!duration) return '';
    // Убираем 'h' если оно уже есть в строке
    return duration.replace(/\s*h\s*/g, '').trim() + ' h';
  };

  // Получение иконки транспорта
  const getVehicleIcon = (vehicleTypes) => {
    if (vehicleTypes?.includes('TRAIN')) {
      return <Train className="h-5 w-5" />;
    }
    return <Bus className="h-5 w-5" />;
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6">
      <div className="flex justify-between items-start mb-6">
        <div className="flex-1">
          {/* Время */}
          <div className="flex items-center mb-3">
            <Clock className="text-[#9a55f4] mr-2" size={20} />
            <span className="font-semibold text-lg">
              {formatTime(route.departureTime)} - {formatTime(route.arrivalTime)}
            </span>
          </div>

          {/* Маршрут */}
          <div className="flex items-center text-gray-700">
            <MapPin className="text-[#9a55f4] mr-2" size={20} />
            <span>
              {getStationName(route.departureStationId)} → {getStationName(route.arrivalStationId)}
            </span>
          </div>
        </div>

        {/* Цена и тип транспорта */}
        <div className="text-right">
          <div className="flex items-center justify-end mb-2">
            <span className="font-bold text-lg">
              {t.price}: {route.price} {route.currency}
            </span>
          </div>
          <div className="flex items-center justify-end text-gray-600">
            {getVehicleIcon(route.vehicleTypes)}
            <span className="ml-1">{route.vehicleTypes?.[0] || t.unknown}</span>
          </div>
        </div>
      </div>

      {/* Дополнительная информация */}
      <div className="border-t pt-6">
        <div className="grid grid-cols-2 gap-4">
          {/* Свободные места */}
          <div className="flex items-center text-gray-600">
            <Users className="text-[#9a55f4] mr-2" size={16} />
            <span>{t.availableSeats}: {route.freeSeatsCount}</span>
          </div>

          {/* Время в пути */}
          <div className="flex items-center text-gray-600">
            <Clock className="text-[#9a55f4] mr-2" size={16} />
            <span>{t.travelTime}: {formatDuration(route.travelTime)}</span>
          </div>
        </div>
      </div>

      {/* Задержка */}
      {route.delay && (
        <div className="mt-4 p-3 bg-red-50 rounded-md">
          <div className="flex items-center text-red-600">
            <Clock className="mr-2" size={16} />
            <span>{t.delay}: {route.delay}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default RouteDetails;