import React, { useState, useEffect } from 'react';
import { 
  useStripe, 
  useElements,
  PaymentElement
} from '@stripe/react-stripe-js';
import { emailService } from '../services/email-service';
import { toast } from 'react-hot-toast';

const logError = (error) => {
  console.group('Подробности ошибки платежа:');
  console.log('Сообщение:', error.message);
  console.log('Название:', error.name);
  console.log('Стек:', error.stack);
  if (error.response) {
    console.log('Ответ сервера:', {
      data: error.response.data,
      status: error.response.status,
      headers: error.response.headers
    });
  }
  console.dir(error, { depth: null });
  console.groupEnd();
};

const PaymentForm = ({ booking, ticketPrice, onPaymentSuccess, language = 'ru' }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!elements || !ticketPrice) return;

    const updateOptions = async () => {
      try {
        const amount = Math.round(ticketPrice * 100);
        
        await elements.update({
          amount: amount,
          currency: 'eur'
        });
      } catch (err) {
        console.error('Ошибка обновления опций Stripe:', err);
        setError('Ошибка инициализации платежной формы');
      }
    };

    updateOptions();
  }, [elements, ticketPrice]);

  // Глобальный обработчик ошибок
  useEffect(() => {
    const handleError = (event) => {
      console.group('Глобальная ошибка:');
      console.log('Сообщение:', event.error?.message);
      console.log('Источник:', event.filename);
      console.log('Строка:', event.lineno);
      console.log('Колонка:', event.colno);
      console.log('Полная ошибка:', event.error);
      console.groupEnd();
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  const handlePaymentSuccess = async (paymentIntent) => {
    try {
      console.log('Processing payment success:', {
        bookingToken: booking.token,
        paymentIntentId: paymentIntent.id
      });

      // Проверяем наличие токена
      if (!booking.token) {
        throw new Error('Booking token is missing');
      }

      // Очищаем токен от префикса Bearer если он есть
      const cleanToken = booking.token.replace(/^Bearer\s+/i, '');

      console.log('Sending authentication request with token:', {
        originalToken: booking.token.substring(0, 10) + '...',
        cleanToken: cleanToken.substring(0, 10) + '...'
      });

      // Получаем accountCode через аутентификацию
      const authResponse = await fetch('/api/users/authenticate', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${cleanToken}`,
          'X-Application-Origin': 'AFF',
          'X-Lang': 'ru',
          'Accept': 'application/json'
        }
      });

      const responseData = await authResponse.json();

      console.log('Authentication response:', {
        status: authResponse.status,
        statusText: authResponse.statusText,
        data: responseData
      });

      if (!authResponse.ok) {
        throw new Error(`Authentication failed: ${responseData.message || 'Unexpected error'}`);
      }

      if (!responseData.accountCode) {
        console.error('Invalid auth response:', responseData);
        throw new Error('Account code not received in response');
      }

      // Сохраняем токен в localStorage
      localStorage.setItem('bookingToken', cleanToken);
      console.log('Saved booking token:', cleanToken.substring(0, 10) + '...');

      // После успешной аутентификации отправляем запрос на оплату билета
      console.log('Sending payment confirmation to RegioJet...', {
        ticketId: booking.id,
        paymentIntentId: paymentIntent.id
      });

      // Создаем Basic auth с API ключом
      const apiAuth = btoa('jobsapp_api:sh63FHlF');

      const paymentResponse = await fetch(`/api/regiojet/payments/${booking.id}/pay`, {
        method: 'PUT',
        headers: {
          'Authorization': `Basic ${apiAuth}`,
          'X-Auth-Token': cleanToken,
          'X-Application-Origin': 'AFF',
          'X-Lang': 'ru',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-TxToken': paymentIntent.id
        }
      });

      if (!paymentResponse.ok) {
        const errorText = await paymentResponse.text();
        console.error('Payment confirmation failed:', {
          status: paymentResponse.status,
          response: errorText
        });
        throw new Error('Failed to confirm payment with RegioJet');
      }

      // Проверяем статус билета
      let attempts = 0;
      let ticketPaid = false;
      const maxAttempts = 6;
      const delayBetweenAttempts = 5000;

      while (attempts < maxAttempts && !ticketPaid) {
        attempts++;
        
        if (attempts > 1) {
          console.log(`Waiting ${delayBetweenAttempts/1000} seconds before next attempt...`);
          await new Promise(resolve => setTimeout(resolve, delayBetweenAttempts));
        }

        console.log(`Checking ticket status (attempt ${attempts}/${maxAttempts})...`);
        const ticketResponse = await fetch(`/api/regiojet/tickets/${responseData.accountCode}/${booking.id}`, {
          headers: {
            'X-Auth-Token': cleanToken,
            'X-Application-Origin': 'AFF',
            'X-Lang': 'ru',
            'Accept': 'application/json'
          }
        });

        if (ticketResponse.ok) {
          const ticketInfo = await ticketResponse.json();
          console.log('Ticket status:', {
            state: ticketInfo.state,
            unpaid: ticketInfo.unpaid,
            paymentId: ticketInfo.paymentId
          });

          // Проверяем что билет оплачен (VALID)
          if (ticketInfo.state === 'VALID') {
            ticketPaid = true;
            console.log('Payment confirmed by RegioJet');
            break;
          }
        } else {
          console.warn('Failed to check ticket status:', ticketResponse.status);
        }
      }

      if (!ticketPaid) {
        throw new Error('Payment confirmation timeout. Please contact support.');
      }

      // Обновляем состояние
      if (onPaymentSuccess) {
        onPaymentSuccess({
          ...booking,
          accountCode: responseData.accountCode,
          paymentIntentId: paymentIntent.id,
          status: 'paid'
        });
      }

    } catch (error) {
      console.error('Payment processing error:', error);
      setError(error.message);
      toast.error('Ошибка при обработке платежа. Пожалуйста, свяжитесь с поддержкой.');
    }
  };

  const handlePaymentError = (error) => {
    let errorMessage = 'Произошла ошибка при оплате';
    
    switch (error.code) {
      case 'card_declined':
        errorMessage = 'Карта отклонена. Пожалуйста, используйте другую карту.';
        break;
      case 'expired_card':
        errorMessage = 'Карта просрочена.';
        break;
      case 'incorrect_cvc':
        errorMessage = 'Неверный код CVC.';
        break;
      case 'processing_error':
        errorMessage = 'Ошибка обработки платежа. Попробуйте позже.';
        break;
      default:
        errorMessage = error.message || 'Неизвестная ошибка';
    }
    
    setError(errorMessage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (!stripe || !elements) {
        throw new Error('Stripe not initialized');
      }

      // Проверяем наличие необходимых данных
      if (!booking || !ticketPrice) {
        throw new Error('Missing booking data or price');
      }

      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw submitError;
      }

      const paymentData = {
        amount: Math.round(ticketPrice * 100),
        currency: 'eur',
        booking_id: booking.id,
        metadata: {
          email: booking.email,
          firstName: booking.firstName,
          lastName: booking.lastName,
          booking_id: booking.id.toString(),
          departure: booking.departureStationName,
          arrival: booking.arrivalStationName,
          departureTime: booking.departureTime,
          arrivalTime: booking.arrivalTime,
          seat: booking.seatNumber,
          vehicle: booking.vehicleNumber,
          language: 'ru'
        }
      };

      console.log('Sending payment request:', paymentData);

      const response = await fetch('/api/stripe/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paymentData)
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.error || 'Ошибка создания платежа');
      }

      console.log('Payment intent created:', responseData);

      const { error: confirmError, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret: responseData.clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/payment/success`,
          payment_method_data: {
            billing_details: {
              email: booking.email,
              name: `${booking.firstName} ${booking.lastName}`
            }
          }
        },
        redirect: 'if_required'
      });

      if (confirmError) {
        throw confirmError;
      }

      if (paymentIntent && paymentIntent.status === 'succeeded') {
        await handlePaymentSuccess(paymentIntent);
      }

    } catch (error) {
      console.error('Payment error:', error);
      setError(error.message || 'Payment processing error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <PaymentElement />
      <button 
        type="submit" 
        disabled={loading || !stripe || !elements}
        className="w-full py-2 px-4 bg-[#9a55f4] text-white rounded-md hover:bg-[#8645e0] disabled:bg-gray-400 mt-5"
      >
        {loading ? 'Обработка...' : 'Оплатить'}
      </button>
      {error && (
        <div className="mt-4 p-3 bg-red-100 text-red-700 rounded-md">
          {error}
        </div>
      )}
    </form>
  );
};

export default PaymentForm;