import axios from 'axios';
import qs from 'qs';
import PaymentAPI from './payment-api';

// Базовая конфигурация API
const CONFIG = {
  BASE_URL: '/api',
  DEFAULT_HEADERS: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Application-Origin': 'AFF',
    'X-Lang': 'en'
  },
  AUTH: {
    USERNAME: 'jobsapp_api',
    PASSWORD: 'sh63FHlF'
  },
  SUPPORTED: {
    LANGUAGES: ['cs', 'de', 'en', 'es', 'fr', 'hu', 'ru', 'sk', 'uk', 'zh'],
    CURRENCIES: ['CZK', 'EUR'],
    LOCATION_TYPES: {
      CITY: 'CITY',
      STATION: 'STATION'
    },
    VEHICLE_TYPES: {
      BUS: 'BUS',
      TRAIN: 'TRAIN'
    },
    PAYMENT_METHODS: {
      REGIOJET_PAY: 'REGIOJET_PAY'
    },
    PAYMENT_STATUSES: {
      PENDING: 'PENDING',
      PAID: 'PAID',
      FAILED: 'FAILED',
      CANCELLED: 'CANCELLED',
      EXPIRED: 'EXPIRED'
    }
  },
  DEFAULTS: {
    TARIFF: 'REGULAR',
    LOCATION_TYPE: 'CITY',
    SEAT_CLASS: 'REGULAR',
    CURRENCY: 'EUR',
    LANG: 'en'
  }
};

class RegioJetAPI {
  constructor() {
    this.CONFIG = CONFIG;
    
    // Создаем инстанс axios с базовыми настройками
    this.api = axios.create({
      baseURL: this.CONFIG.BASE_URL, // /api
      headers: this.CONFIG.DEFAULT_HEADERS,
      paramsSerializer: {
        serialize: params => qs.stringify(params, {
          arrayFormat: 'repeat',
          encode: false
        })
      }
    });

    // Создаем отдельный инстанс для authenticate запросов
    this.authApi = axios.create({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Application-Origin': 'AFF',
        'X-Lang': 'en'
      }
    });

    this.setupInterceptors();
    this.payment = new PaymentAPI(this);
  }

  // Отдельный метод для аутентификации
  createAuthRequest(token) {
    const cleanToken = token.trim();
    return {
      method: 'GET',
      url: '/api/users/authenticate',
      headers: {
        'Authorization': `Bearer ${cleanToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Application-Origin': 'AFF',
        'X-Lang': 'en'
      }
    };
  }

  btoa(str) {
    try {
      return window.btoa(str);
    } catch (err) {
      return window.btoa(unescape(encodeURIComponent(str)));
    }
  }

  setupInterceptors() {
    this.api.interceptors.request.use(
      config => {
        console.log('Making request:', {
          url: config.url,
          method: config.method,
          headers: this.sanitizeHeaders(config.headers)
        });
  
        // Для эндпоинта аутентификации не добавляем Basic auth
        if (config.url.includes('/users/authenticate')) {
          return config;
        }
  
        // Для остальных запросов используем Basic auth
        const auth = this.btoa(`${CONFIG.AUTH.USERNAME}:${CONFIG.AUTH.PASSWORD}`);
        config.headers.Authorization = `Basic ${auth}`;
        
        // Добавляем стандартные заголовки
        config.headers = {
          ...config.headers,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Application-Origin': 'AFF',
          'X-Lang': 'en'
        };
  
        return config;
      },
      error => {
        console.error('Request interceptor error:', error);
        return Promise.reject(error);
      }
    );
  
    this.api.interceptors.response.use(
      response => {
        console.log('Response:', {
          url: response.config.url,
          status: response.status,
          hasData: !!response.data
        });
        return response;
      },
      error => {
        // Подробное логирование ошибок
        const errorDetails = {
          url: error.config?.url,
          method: error.config?.method,
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data,
          headers: this.sanitizeHeaders(error.config?.headers)
        };
  
        // Специальная обработка ошибок аутентификации
        if (error.response?.status === 401) {
          if (error.config?.url.includes('/users/authenticate')) {
            console.error('Authentication failed:', errorDetails);
            return Promise.reject(new Error('Authentication failed: Invalid token'));
          }
          console.error('Authorization error:', errorDetails);
          return Promise.reject(new Error('Authorization failed: Invalid credentials'));
        }
  
        // Общее логирование ошибок
        console.error('API Error:', errorDetails);
  
        // Обработка специфичных ошибок
        if (error.response?.data?.message) {
          return Promise.reject(new Error(error.response.data.message));
        }
  
        // Обработка сетевых ошибок
        if (error.request && !error.response) {
          console.error('Network error:', error);
          return Promise.reject(new Error('Network error: Unable to reach the server'));
        }
  
        return Promise.reject(this.handleError(error));
      }
    );
  
    // Добавляем отдельный обработчик для отмены запросов
    const cancelToken = axios.CancelToken;
    this.requestCancelSource = cancelToken.source();
  }

  async authenticate(token) {
    try {
      if (!token) {
        throw new Error('Token is required');
      }
  
      const cleanToken = token.trim().replace(/^Bearer\s+/i, '');
      
      console.log('Authentication attempt:', {
        tokenPreview: cleanToken.substring(0, 10) + '...',
        length: cleanToken.length,
        fullToken: cleanToken // для отладки
      });
  
      const config = {
        method: 'POST', // Меняем на POST
        url: '/tickets/payment/authenticate', // Меняем путь
        headers: {
          'Authorization': `Bearer ${cleanToken}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Application-Origin': 'AFF'
        },
        data: {
          token: cleanToken,
          bookingId: this.lastBookingId // Добавляем ID бронирования
        }
      };
  
      console.log('Auth request config:', {
        url: config.url,
        method: config.method,
        headers: {
          ...config.headers,
          Authorization: config.headers.Authorization.substring(0, 20) + '...'
        }
      });
  
      const response = await this.api(config);
  
      console.log('Auth response:', {
        status: response.status,
        hasData: !!response.data
      });
  
      return response.data;
  
    } catch (error) {
      console.error('Auth error:', {
        status: error.response?.status,
        message: error.message,
        data: error.response?.data,
        config: error.config
      });
  
      if (error.response?.status === 401) {
        throw new Error('Authentication failed: Invalid or expired token');
      }
  
      throw new Error('Authentication failed: ' + (error.response?.data?.message || error.message));
    }
  }

  // Location methods
  async getLocations(type = null) {
    try {
      console.log('Fetching locations:', { type });
      const params = type ? { stationType: type } : {};
      const response = await this.api.get('/consts/locations', { params });
      return this.processLocationsResponse(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getStationsByCity(cityId) {
    try {
      const locations = await this.getLocations();
      const city = locations.find(city => city.id === cityId);
      return city?.stations || [];
    } catch (error) {
      console.error('Error fetching stations for city:', cityId, error);
      throw this.handleError(error);
    }
  }

  async getStationById(stationId) {
    try {
      const locations = await this.getLocations();
      for (const city of locations) {
        const station = city.stations.find(station => station.id === stationId);
        if (station) {
          return {
            ...station,
            cityName: city.name,
            countryCode: city.countryCode
          };
        }
      }
      throw new Error(`Station with ID ${stationId} not found`);
    } catch (error) {
      console.error('Error fetching station info:', stationId, error);
      throw this.handleError(error);
    }
  }

 // Response processors
processTariffsResponse(data) {
  if (!Array.isArray(data)) {
    console.warn('Unexpected tariffs data format');
    return [];
  }

  return data.map(tariff => ({
    key: tariff.key || tariff.code,
    value: tariff.value || tariff.name,
    name: tariff.name,
    description: tariff.description,
    priceMultiplier: Number(tariff.priceMultiplier || 1),
    isDefault: Boolean(tariff.isDefault),
    validFrom: tariff.validFrom ? new Date(tariff.validFrom) : null,
    validTo: tariff.validTo ? new Date(tariff.validTo) : null,
    conditions: tariff.conditions || [],
    benefits: tariff.benefits || [],
    eligibilityRules: tariff.eligibilityRules || [],
    requiredDocuments: tariff.requiredDocuments || []
  }));
}

processSeatClassesResponse(data) {
  if (!Array.isArray(data)) {
    console.warn('Unexpected seat classes data format');
    return [];
  }

  return data.map(seatClass => ({
    key: seatClass.key,
    vehicleClass: seatClass.vehicleClass,
    title: seatClass.title,
    description: seatClass.description,
    imageUrl: seatClass.imageUrl,
    galleryUrl: seatClass.galleryUrl,
    priceMultiplier: Number(seatClass.priceMultiplier || 1),
    isDefault: Boolean(seatClass.isDefault),
    features: seatClass.features || [],
    services: seatClass.services || []
  }));
}

processRouteDetailsResponse(data) {
  if (!data) return null;

  return {
    ...data,
    departureTime: data.departureTime ? new Date(data.departureTime) : null,
    arrivalTime: data.arrivalTime ? new Date(data.arrivalTime) : null,
    priceClasses: data.priceClasses?.map(priceClass => ({
      ...priceClass,
      price: Number(priceClass.price),
      creditPrice: Number(priceClass.creditPrice)
    })) || [],
    mainSectionId: data.mainSectionId,
    priceSource: data.priceClasses?.[0]?.priceSource
  };
}

processSeatsResponse(data) {
  if (!Array.isArray(data)) {
    console.warn('Unexpected seats data format');
    return [];
  }

  return data.map(section => ({
    sectionId: section.sectionId,
    fromStationId: section.fromStationId,
    toStationId: section.toStationId,
    fixedSeatReservation: section.fixedSeatReservation,
    vehicles: (section.vehicles || []).map(vehicle => ({
      id: vehicle.id || vehicle.vehicleId,
      number: vehicle.vehicleNumber || vehicle.number,
      standardKey: vehicle.vehicleStandardKey || vehicle.standardKey,
      services: vehicle.services || [],
      seats: (vehicle.freeSeats || []).map(seat => {
        const seatIndex = typeof seat.index === 'number' ? seat.index : parseInt(seat.number, 10);
        return {
          id: `${vehicle.vehicleNumber}-${seatIndex}`,
          index: seatIndex,
          number: seat.number || seatIndex.toString(),
          seatClass: seat.seatClass || CONFIG.DEFAULTS.SEAT_CLASS,
          constraint: seat.seatConstraint,
          notes: seat.seatNotes || [],
          available: true,
          price: seat.price,
          currency: seat.currency || CONFIG.DEFAULTS.CURRENCY,
          vehicleNumber: vehicle.vehicleNumber || vehicle.number
        };
      })
    }))
  }));
}

processTicketResponse(data) {
  const ticketData = data.tickets?.[0] || data;
  
  return {
    id: ticketData.id || ticketData.ticketId,
    price: ticketData.price || ticketData.totalPrice,
    accountCode: ticketData.accountCode,
    status: ticketData.status || 'PENDING',
    departureTime: ticketData.departureTime,
    arrivalTime: ticketData.arrivalTime,
    fromStation: ticketData.fromStation,
    toStation: ticketData.toStation,
    passengers: ticketData.passengers,
    seats: ticketData.seats,
    qrCode: ticketData.qrCode,
    customerActions: ticketData.customerActions || {}
  };
} 

  // Route methods
  async searchRoutes({
    fromLocationId,
    toLocationId,
    departureDate,
    fromLocationType = CONFIG.DEFAULTS.LOCATION_TYPE,
    toLocationType = CONFIG.DEFAULTS.LOCATION_TYPE,
    tariffs = [CONFIG.DEFAULTS.TARIFF]
  }) {
    try {
      const params = {
        fromLocationId: this.parseId(fromLocationId),
        toLocationId: this.parseId(toLocationId),
        fromLocationType,
        toLocationType,
        departureDate: this.formatDate(departureDate),
        tariffs
      };

      const response = await this.api.get('/routes/search/simple', { params });
      return this.processRoutesResponse(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getRouteDetails({
    routeId,
    fromStationId,
    toStationId,
    departureDate,
    tariffs = [CONFIG.DEFAULTS.TARIFF]
  }) {
    try {
      if (!routeId || !fromStationId || !toStationId || !departureDate) {
        throw new Error('Required parameters missing');
      }

      const params = {
        fromStationId: this.parseId(fromStationId),
        toStationId: this.parseId(toStationId),
        departureDate: this.formatDate(departureDate),
        tariffs: Array.isArray(tariffs) ? tariffs : [tariffs]
      };

      console.log('Getting route details:', { routeId, params });

      const response = await this.api.get(`/routes/${routeId}/simple`, { params });
      return this.processRouteDetailsResponse(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getFreeSeats(routeId, params = {}) {
    try {
      if (!routeId || !params.fromStationId || !params.toStationId) {
        throw new Error('Required parameters missing for seats request');
      }

      const requestData = {
        sections: [{
          sectionId: routeId,
          fromStationId: this.parseId(params.fromStationId),
          toStationId: this.parseId(params.toStationId)
        }],
        tariffs: [params.tariff || CONFIG.DEFAULTS.TARIFF],
        seatClass: params.seatClass || CONFIG.DEFAULTS.SEAT_CLASS
      };

      const response = await this.api.post(`/routes/${routeId}/freeSeats`, requestData);
      return this.processSeatsResponse(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getPassengersData(routeId, data) {
    try {
      const response = await this.api.post(`/routes/${routeId}/passengersData`, data);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getVehicleStandards() {
    try {
      const response = await this.api.get('/consts/vehicleStandards');
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  // Tariff methods
  async getTariffs() {
    try {
      console.log('Fetching tariffs');
      const response = await this.api.get('/consts/tariffs');
      return this.processTariffsResponse(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getSeatClasses() {
    try {
      console.log('Fetching seat classes');
      const response = await this.api.get('/consts/seatClasses');
      return this.processSeatClassesResponse(response.data);
    } catch (error) {
      console.error('Error fetching seat classes:', error);
      throw this.handleError(error);
    }
  }

  async getDefaultTariff() {
    try {
      console.log('Getting default tariff');
      const tariffs = await this.getTariffs();
      const defaultTariff = tariffs.find(tariff => tariff.isDefault) || tariffs[0];
      
      console.log('Default tariff:', defaultTariff);
      return defaultTariff;
    } catch (error) {
      console.error('Error getting default tariff:', error);
      throw this.handleError(error);
    }
  }

  async getTariffByKey(tariffKey) {
    try {
      const tariffs = await this.getTariffs();
      const tariff = tariffs.find(t => t.key === tariffKey);
      if (!tariff) {
        throw new Error(`Tariff with key ${tariffKey} not found`);
      }
      return tariff;
    } catch (error) {
      console.error('Error getting tariff by key:', error);
      throw this.handleError(error);
    }
  }

  async validateTariff(tariffKey, passengerData) {
    try {
      const tariff = await this.getTariffByKey(tariffKey);
      
      console.log('Validating tariff:', {
        tariffKey,
        passengerData: this.sanitizeData(passengerData)
      });

      if (tariff.validFrom && new Date() < tariff.validFrom) {
        throw new Error('Tariff is not yet valid');
      }
      if (tariff.validTo && new Date() > tariff.validTo) {
        throw new Error('Tariff has expired');
      }

      if (tariff.eligibilityRules?.length) {
        const isEligible = tariff.eligibilityRules.every(rule => {
          switch (rule.type) {
            case 'AGE':
              const age = this.calculateAge(new Date(passengerData.dateOfBirth));
              return age >= rule.minAge && age <= rule.maxAge;
            case 'DOCUMENT':
              return rule.requiredDocuments.some(doc => 
                passengerData.documents?.includes(doc)
              );
            default:
              return true;
          }
        });

        if (!isEligible) {
          throw new Error('Passenger is not eligible for this tariff');
        }
      }

      return true;
    } catch (error) {
      console.error('Tariff validation error:', error);
      throw this.handleError(error);
    }
  }

  // Ticket methods
  async createTicket(bookingData) {
    try {
      console.log('Creating ticket with data:', {
        routeId: bookingData.routeId,
        seatClass: bookingData.selectedSeats?.[0]?.seatClass,
        passengers: bookingData.passengers?.length
      });

      // Форматируем дату рождения в нужный формат (YYYY-MM-DD)
      const formatDate = (date) => {
        const d = new Date(date);
        return d.toISOString().split('T')[0];
      };

      const ticketRequest = {
        agreeWithTerms: true,
        ticketRequests: bookingData.selectedSeats.map((seat, index) => ({
          route: {
            routeId: parseInt(bookingData.routeId),
            seatClass: bookingData.selectedRoute.seatClass || 'BUS_STANDARD',
            priceSource: bookingData.selectedRoute.priceSource,
            sections: [{
              section: {
                sectionId: parseInt(bookingData.selectedRoute.mainSectionId || bookingData.routeId),
                fromStationId: parseInt(bookingData.selectedRoute.departureStationId),
                toStationId: parseInt(bookingData.selectedRoute.arrivalStationId)
              },
              selectedSeats: [{
                sectionId: parseInt(bookingData.selectedRoute.mainSectionId || bookingData.routeId),
                vehicleNumber: parseInt(seat.vehicleNumber),
                seatIndex: parseInt(seat.number)
              }]
            }]
          },
          passengers: [{
            firstName: bookingData.passengers[index].firstName?.trim() || '',
            surname: bookingData.passengers[index].lastName?.trim() || '',
            email: bookingData.passengers[index].email?.trim() || '',
            phone: bookingData.passengers[index].phone || '',
            dateOfBirth: formatDate(bookingData.passengers[index].dateOfBirth),
            tariff: 'REGULAR'
          }]
        }))
      };

      // Очищаем запрос от null и undefined значений
      const cleanRequest = JSON.parse(JSON.stringify(ticketRequest), (key, value) => {
        if (value === null || value === undefined || value === '') {
          return undefined;
        }
        return value;
      });

      console.log('Sending ticket request:', JSON.stringify(cleanRequest, null, 2));

      const response = await this.api.post('/tickets/create', cleanRequest, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Application-Origin': 'AFF',
          'X-Lang': 'en'
        }
      });
      
      if (!response.data) {
        throw new Error('Empty response from ticket creation');
      }

      // Добавляем логирование ответа
      console.log('Ticket creation response:', response.data);

      // Проверяем структуру ответа
      const ticketData = response.data.tickets?.[0] || response.data;
      const token = response.data.token || ticketData.token;

      if (!ticketData) {
        throw new Error('Invalid ticket data structure');
      }

      // Обрабатываем ответ
      return {
        ticket: {
          id: ticketData.id || ticketData.ticketId,
          price: ticketData.price || ticketData.totalPrice,
          accountCode: ticketData.accountCode,
          status: ticketData.status || 'PENDING',
          departureTime: ticketData.departureTime,
          arrivalTime: ticketData.arrivalTime,
          fromStation: ticketData.fromStation,
          toStation: ticketData.toStation,
          passengers: ticketData.passengers,
          seats: ticketData.seats,
          qrCode: ticketData.qrCode,
          customerActions: ticketData.customerActions || {}
        },
        token: token
      };

    } catch (error) {
      console.error('Ticket creation failed:', error);
      throw error;
    }
  }

  // Additional Ticket Methods
  async cancelTicket(ticketId, token) {
    try {
      const response = await this.makeRequest({
        url: `/tickets/${ticketId}/cancel`,
        method: 'post',
        headers: {
          'X-Auth-Token': token
        }
      });

      return {
        success: true,
        refundAmount: response.data.refundAmount,
        currency: response.data.currency
      };
    } catch (error) {
      console.error('Error cancelling ticket:', error);
      throw new Error(error.response?.data?.message || 'Failed to cancel ticket');
    }
  }

  async getTickets(accountCode) {
    try {
      const response = await this.api.get(`/tickets/${accountCode}`);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getTicketById(accountCode, ticketId) {
    try {
      const response = await this.api.get(`/tickets/${accountCode}/${ticketId}`);
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async printTicket(accountCode, ticketId, token) {
    try {
      const cleanToken = token.replace(/^Bearer\s+/i, '').trim();
      const authToken = `Bearer ${cleanToken}`;

      return await this.api.get(
        `/tickets/${accountCode}/${ticketId}/print`,
        {
          responseType: 'blob',
          headers: {
            'Authorization': authToken,
            'Accept': 'text/html'
          }
        }
      );
    } catch (error) {
      console.error('Print ticket error:', error);
      throw this.handleError(error);
    }
  }

  async getTicketQR(accountCode, ticketId, token) {
    try {
      const cleanToken = token.replace(/^Bearer\s+/i, '').trim();
      const authToken = `Bearer ${cleanToken}`;

      return await this.api.get(
        `/tickets/${accountCode}/${ticketId}/qrcode/png`,
        {
          responseType: 'blob',
          headers: {
            'Authorization': authToken,
            'Accept': 'image/png'
          }
        }
      );
    } catch (error) {
      console.error('Get ticket QR error:', error);
      throw this.handleError(error);
    }
  }

  async evaluateTicket(accountCode, ticketId, rating) {
    try {
      const response = await this.api.post(
        `/tickets/${accountCode}/${ticketId}/evaluate`,
        rating
      );
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async sendTicketByEmail(accountCode, ticketId, email) {
    try {
      const response = await this.api.post(
        `/tickets/${accountCode}/${ticketId}/send`,
        { email }
      );
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async printInvoice(accountCode, ticketId) {
    try {
      const response = await this.api.get(
        `/tickets/${accountCode}/${ticketId}/invoice`,
        { responseType: 'blob' }
      );
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

 async payTicket(ticketId, token) {
    try {
      console.log('Starting payment:', {
        ticketId,
        tokenPreview: token.substring(0, 10) + '...'
      });

      const response = await this.api({
        method: 'PUT',
        url: `/payments/${ticketId}/pay`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Application-Origin': 'AFF',
          'X-Lang': 'en',
          'Content-Type': 'application/json'
        }
      });

      console.log('Payment response:', {
        status: response.status,
        ticketId,
        success: response.status === 200
      });

      return {
        success: response.status === 200,
        ticketId,
        status: 'PAID'
      };

    } catch (error) {
      console.error('Payment error:', {
        ticketId,
        status: error.response?.status,
        message: error.message,
        data: error.response?.data
      });

      throw new Error(error.response?.data?.message || 'Payment failed');
    }
  }
  
  async paySroBooking(bookingToken) {
      try {
        console.log('Starting SRO payment:', {
          bookingToken: bookingToken.substring(0, 10) + '...'
        });
  
        const response = await this.api({
          method: 'PUT',
          url: `/payments/RJ_SRO/${bookingToken}/pay`,
          headers: {
            'X-Application-Origin': 'AFF',
            'X-Lang': 'en',
            'Content-Type': 'application/json'
          }
        });
  
        console.log('SRO payment response:', {
          status: response.status,
          success: [201, 204].includes(response.status)
        });
  
        return {
          success: [201, 204].includes(response.status),
          bookingToken
        };
  
      } catch (error) {
        console.error('SRO payment error:', {
          status: error.response?.status,
          message: error.message,
          data: error.response?.data
        });
  
        throw new Error(error.response?.data?.message || 'SRO payment failed');
      }
    }
  
  // Response processors
  processLocationsResponse(data) {
    if (!Array.isArray(data)) {
      console.warn('Unexpected locations data format');
      return [];
    }

    return data.flatMap(country => 
      (country.cities || []).map(city => ({
        id: city.id,
        name: city.name,
        countryCode: country.code,
        type: CONFIG.SUPPORTED.LOCATION_TYPES.CITY,
        aliases: city.aliases || [],
        stations: (city.stations || []).map(station => ({
          id: station.id,
          name: station.fullname || station.name,
          type: station.stationsTypes?.[0] || CONFIG.SUPPORTED.LOCATION_TYPES.STATION,
          address: station.address,
          latitude: station.latitude,
          longitude: station.longitude,
          significance: station.significance || 0
        }))
      }))
    );
  }

  processRoutesResponse(data) {
    if (!data?.routes) {
      return { routes: [], message: data?.routesMessage };
    }

    return {
      routes: data.routes.map(route => ({
        id: route.id,
        departureTime: new Date(route.departureTime),
        arrivalTime: new Date(route.arrivalTime),
        departureStationId: route.departureStationId || route.fromStationId,
        arrivalStationId: route.arrivalStationId || route.toStationId,
        price: route.priceFrom,
        currency: route.currency || CONFIG.DEFAULTS.CURRENCY,
        vehicleTypes: route.vehicleTypes || [],
        freeSeatsCount: route.freeSeatsCount,
        transfersCount: route.transfersCount || 0,
        bookable: route.bookable,
        mainSectionId: route.mainSectionId,
        priceSource: route.priceSource || `${route.id}<${route.departureStationId || route.fromStationId}-${route.arrivalStationId || route.toStationId}>`,
        travelTime: route.travelTime,
        delay: route.delay
      })),
      message: data.routesMessage
    };
  }

  // Error handlers
  handleError(error) {
    if (error.response) {
      const { data, status } = error.response;
      let message = data?.message || 'An unexpected error occurred';

      if (data?.errorFields?.length) {
        message = data.errorFields
          .map(field => `${field.field}: ${field.message}`)
          .join('; ');
      }

      const apiError = new Error(message);
      apiError.status = status;
      apiError.details = data;
      return apiError;
    }

    if (error.request) {
      return new Error('No response received from server');
    }

    return error;
  }

  handlePaymentError(error) {
    if (error.response) {
      const { status, data } = error.response;
      const message = data?.message || 'Payment operation failed';

      switch (status) {
        case 400: return new Error(`Invalid payment request: ${message}`);
        case 401: return new Error('Payment authorization failed');
        case 403: return new Error('Payment operation forbidden');
        case 404: return new Error('Payment not found');
        case 409: return new Error('Payment already processed');
        case 500: return new Error('Payment service temporarily unavailable');
        default: return new Error(`Payment error: ${message}`);
      }
    }

    if (error.request) {
      return new Error('Payment service is not responding');
    }

    return new Error(error.message || 'Unexpected payment error');
  }

  // Utility methods
  validateBookingData(data) {
    if (!data?.routeId || !data?.selectedRoute || !data?.selectedSeats?.[0] || !data?.passengers?.[0]) {
      console.error('Missing required data:', {
        hasRouteId: !!data?.routeId,
        hasSelectedRoute: !!data?.selectedRoute,
        hasSelectedSeats: !!data?.selectedSeats?.[0],
        hasPassengers: !!data?.passengers?.[0]
      });
      return false;
    }

    const requiredFields = {
      selectedRoute: ['departureStationId', 'arrivalStationId'],
      selectedSeats: ['number', 'vehicleNumber', 'seatClass'],
      passengers: ['firstName', 'lastName', 'email', 'phone', 'dateOfBirth']
    };

    for (const [key, fields] of Object.entries(requiredFields)) {
      for (const field of fields) {
        if (!data[key]?.[0]?.[field] && !data[key]?.[field]) {
          console.error(`Missing required field: ${key}.${field}`);
          return false;
        }
      }
    }

    return true;
  }

  parseId(id) {
    if (typeof id === 'number') return id;
    if (typeof id === 'string') return parseInt(id, 10);
    if (typeof id === 'object' && id !== null) {
      if ('id' in id) return this.parseId(id.id);
      if ('stationId' in id) return this.parseId(id.stationId);
    }
    throw new Error(`Invalid ID format: ${JSON.stringify(id)}`);
  }

  formatDate(date) {
    try {
      if (date instanceof Date) {
        return date.toISOString().split('T')[0];
      }
      if (typeof date === 'string') {
        const dateObj = new Date(date);
        if (isNaN(dateObj.getTime())) {
          throw new Error('Invalid date string');
        }
        return dateObj.toISOString().split('T')[0];
      }
      throw new Error('Invalid date format');
    } catch (error) {
      throw new Error(`Date formatting error: ${error.message}`);
    }
  }

  calculateAge(birthDate) {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    
    return age;
  }

  sanitizeData(data) {
    if (!data) return data;
    const sensitiveFields = ['password', 'token', 'auth', 'creditCard'];
    return JSON.parse(JSON.stringify(data), (key, value) => 
      sensitiveFields.includes(key) ? '[REDACTED]' : value
    );
  }

  sanitizeHeaders(headers) {
    if (!headers) return headers;
    const sanitized = { ...headers };
    if (sanitized.Authorization) {
      sanitized.Authorization = sanitized.Authorization.substring(0, 20) + '...';
    }
    return sanitized;
  }

  getStatusMessage(status) {
    const messages = {
      PENDING: 'Payment is being processed',
      PAID: 'Payment completed successfully',
      FAILED: 'Payment failed',
      CANCELLED: 'Payment was cancelled',
      EXPIRED: 'Payment has expired'
    };
    return messages[status] || `Unknown payment status: ${status}`;
  }

  async sendTicketEmail({ ticketId, email, token }) {
    try {
      const response = await this.api.post(`/tickets/${ticketId}/email`, {
        email: email,
        token: token
      });
      return response.data;
    } catch (error) {
      console.error('Failed to send ticket email:', error);
      throw error;
    }
  }

  // Добавить функцию для сохранения бронирования
  async saveBooking(bookingData) {
    try {
      const response = await fetch('/api/bookings/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          booking_id: bookingData.id,
          email: bookingData.email,
          firstName: bookingData.firstName,
          lastName: bookingData.lastName,
          departureStation: bookingData.departureStationName,
          arrivalStation: bookingData.arrivalStationName,
          departureTime: bookingData.departureTime,
          arrivalTime: bookingData.arrivalTime,
          seatNumber: bookingData.seatNumber,
          vehicleNumber: bookingData.vehicleNumber,
          price: bookingData.price,
          currency: bookingData.currency,
          status: 'PENDING',
          paymentIntentId: bookingData.paymentIntentId,
          bookingToken: bookingData.bookingToken,
          accountCode: bookingData.accountCode
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to save booking');
      }

      return await response.json();
    } catch (error) {
      console.error('Error saving booking:', error);
      throw error;
    }
  }
}

export default new RegioJetAPI();