import React from 'react';
import { Loader } from 'lucide-react';

const LoadingScreen = ({ t }) => (
  <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-white">
    <Loader className="animate-spin text-[#9a55f4] mb-4" size={48} />
    <h2 className="text-2xl font-semibold text-gray-700">{t.loading}</h2>
    <p className="text-gray-500 mt-2">{t.pleaseWait}</p>
  </div>
);

export default LoadingScreen;