import React, { useState, useEffect } from 'react';
import { Clock, MapPin, DollarSign, Info } from 'lucide-react';
import api from '../services/regiojet-api';
import { getStationName } from '../utils/stations';

const SeatSelection = ({ routeId, selectedRoute, onSeatSelect, passengers, t }) => {
  // States
  const [seats, setSeats] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [vehicleType, setVehicleType] = useState(null);

  // Fetch seats when route is selected
  useEffect(() => {
    const fetchSeats = async () => {
      try {
        setLoading(true);
        setError(null);

        if (!routeId || !selectedRoute?.departureStationId || !selectedRoute?.arrivalStationId) {
          throw new Error('Missing required route data');
        }

        const seatsData = await api.getFreeSeats(routeId, {
          fromStationId: selectedRoute.departureStationId,
          toStationId: selectedRoute.arrivalStationId,
          tariff: selectedRoute.tariff || 'REGULAR',
          seatClass: selectedRoute.seatClass || 'REGULAR'
        });

        if (!seatsData?.length) {
          setSeats([]);
          return;
        }

        setSeats(seatsData);
      } catch (err) {
        console.error('Error fetching seats:', err);
        setError(err.message || t.errorFetchingSeats);
      } finally {
        setLoading(false);
      }
    };

    if (routeId && selectedRoute) {
      fetchSeats();
    }
  }, [routeId, selectedRoute, t]);

  // Handle seat selection
  const handleSeatSelect = (seat, vehicle) => {
    if (!seat.available) {
      return;
    }

    const seatData = {
      number: seat.number,
      vehicleNumber: vehicle.number,
      seatClass: seat.seatClass || 'REGULAR'
    };

    setSelectedSeats(prev => {
      // Если место уже выбрано - удаляем его
      if (prev.find(s => s.number === seat.number && s.vehicleNumber === vehicle.number)) {
        return prev.filter(s => !(s.number === seat.number && s.vehicleNumber === vehicle.number));
      }

      // Проверяем, не превышает ли количество выбранных мест количество пассажиров
      if (prev.length >= Number(passengers)) {
        return prev;
      }

      // Добавляем новое место
      return [...prev, seatData];
    });
  };

  // Функция для определения типа транспорта
  const determineVehicleType = (vehicle) => {
    if (vehicle.standardKey?.includes('TRAIN')) return 'train';
    return 'bus';
  };

  // Функция для рендеринга сидений в поезде
  const renderTrainSeats = (vehicle) => {
    const compartments = [];
    const seats = vehicle.seats.filter(seat => seat.number && seat.available !== false);
    
    // Группируем места по купе (4 места в каждом)
    for (let i = 0; i < seats.length; i += 4) {
      const compartmentSeats = seats.slice(i, i + 4);
      compartments.push(
        <div key={`compartment-${i}`} className="flex flex-col bg-gray-50 p-2 rounded-lg">
          <div className="grid grid-cols-2 gap-2">
            {compartmentSeats.map(seat => renderSeat(seat, vehicle))}
          </div>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {compartments}
      </div>
    );
  };

  // Функция для рендеринга сидений в автобусе
  const renderBusSeats = (vehicle) => {
    const seats = vehicle.seats.filter(seat => seat.number);
    const rows = [];
    
    // Группируем места по рядам (4 места в ряду: 2+2)
    for (let i = 0; i < seats.length; i += 4) {
      const rowSeats = seats.slice(i, i + 4);
      rows.push(
        <div key={`row-${i}`} className="flex justify-center space-x-8">
          <div className="flex space-x-2">
            {rowSeats.slice(0, 2).map(seat => renderSeat(seat, vehicle))}
          </div>
          {/* Проход */}
          <div className="w-4 bg-gray-200 rounded"></div>
          <div className="flex space-x-2">
            {rowSeats.slice(2, 4).map(seat => renderSeat(seat, vehicle))}
          </div>
        </div>
      );
    }

    return (
      <div className="space-y-2">
        {/* Кабина водителя */}
        <div className="w-full h-12 bg-gray-300 rounded-t-xl mb-4"></div>
        {rows}
      </div>
    );
  };

  // Обновленная функция рендеринга отдельного места
  const renderSeat = (seat, vehicle) => {
    const isSelected = selectedSeats.find(
      s => s.number === seat.number && s.vehicleNumber === vehicle.number
    );
    
    const seatClasses = `
      seat 
      ${!seat.available ? 'bg-gray-300 cursor-not-allowed' : 
        isSelected ? 'bg-green-500 text-white' : 'bg-white hover:bg-gray-100'}
      ${selectedSeats.length >= Number(passengers) && !isSelected ? 'cursor-not-allowed opacity-50' : ''}
      p-2 rounded-md text-center cursor-pointer transition-colors
    `;

    return (
      <div
        key={seat.number}
        className={seatClasses}
        onClick={() => handleSeatSelect(seat, vehicle)}
      >
        <span className="text-sm">{seat.number}</span>
      </div>
    );
  };

  // Добавим функцию для преобразования кодов сервисов в читаемый вид
  const getServiceIcon = (serviceCode) => {
    const serviceMap = {
      'typy_vozidel_wifi_ano': { icon: '📶', label: t.wifi },
      'typy_vozidel_klimatizace': { icon: '❄️', label: t.airConditioner },
      'typy_vozidel_denni_tisk': { icon: '📰', label: t.press },
      'typy_vozidel_teple_napoje_zdarma': { icon: '☕', label: t.hotDrinks },
      'typy_vozidel_obcerstveni': { icon: '🍽️', label: t.food },
      'typy_vozidel_tiche_kupe': { icon: '🤫', label: t.quietZone },
      'typy_vozidel_zabavni_portal_ano': { icon: '🎮', label: t.entertainment },
      'typy_vozidel_sluchatka': { icon: '🎧', label: t.headphones },
      'typy_vozidel_zasuvka': { icon: '🔌', label: t.socket },
      'typy_vozidel_stevardka': { icon: '👨‍✈️', label: t.steward },
      'typy_vozidel_imobilni_rampa': { icon: '♿', label: t.wheelchairRamp }
    };

    return serviceMap[serviceCode] || { icon: '✨', label: t.unknownService };
  };

  // Функция для определения класса вагона
  const getVehicleClass = (standardKey) => {
    const classMap = {
      'YELLOW': t.economyClass,
      'BLUE': t.businessClass,
      'RED': t.firstClass
    };

    const className = standardKey?.split('_').pop();
    return classMap[className] || className;
  };

  // Обновляем рендер информации о вагоне
  const renderVehicleHeader = (vehicle) => {
    const vehicleClass = getVehicleClass(vehicle.standardKey);
    
    return (
      <div className="mb-6">
        {/* Заголовок вагона */}
        <div className="flex items-center justify-between mb-4">
          <div>
            <h4 className="text-lg font-medium">
              {vehicle.standardKey?.includes('TRAIN') ? t.carNumber : t.busNumber} {vehicle.number}
            </h4>
            <span className="text-sm text-gray-600">
              {vehicleClass}
            </span>
          </div>
        </div>

        {/* Сервисы */}
        {vehicle.services?.length > 0 && (
          <div className="flex flex-wrap gap-3">
            {vehicle.services.map((service, index) => {
              const { icon, label } = getServiceIcon(service);
              return (
                <div
                  key={index}
                  className="flex items-center gap-1 px-3 py-1.5 bg-gray-50 rounded-full text-sm"
                  title={label}
                >
                  <span>{icon}</span>
                  <span className="text-gray-700">{label}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  // Добавляем функцию форматирования времени
  const formatTime = (dateString) => {
    try {
      return new Date(dateString).toLocaleTimeString('ru-RU', {
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Error formatting time:', error);
      return dateString;
    }
  };

  // Loading state
  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        <p className="mt-4 text-gray-600">{t.loadingSeats}</p>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
        <div className="flex items-center space-x-2 text-red-600 mb-2">
          <Info size={20} />
          <p className="font-medium">{error}</p>
        </div>
        <button
          onClick={() => window.location.reload()}
          className="mt-4 px-4 py-2 bg-red-100 text-red-700 rounded hover:bg-red-200 transition-colors"
        >
          {t.retry}
        </button>
      </div>
    );
  }

  // No seats available
  if (!seats.length) {
    return (
      <div className="p-6 text-center">
        <p className="text-gray-600 mb-2">{t.noSeatsAvailable}</p>
        <p className="text-sm text-gray-500">{t.selectDifferentRoute}</p>
      </div>
    );
  }

  // Main content
  return (
    <div className="mt-6 space-y-6">
      {/* Первый блок с основной информацией о маршруте */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <div className="flex flex-col space-y-4">
          {/* Маршрут */}
          <div className="flex items-center">
            <MapPin className="text-[#9a55f4] mr-2" size={20} />
            <span className="text-lg font-medium">
              {selectedRoute && selectedRoute.departureStationId && selectedRoute.arrivalStationId ? (
                `${getStationName(selectedRoute.departureStationId, t.locale)} → ${getStationName(selectedRoute.arrivalStationId, t.locale)}`
              ) : (
                t.routeNotAvailable || 'Маршрут недоступен'
              )}
            </span>
          </div>
          
          {/* Время отправления и прибытия */}
          <div className="flex items-center text-gray-600">
            <Clock className="text-[#9a55f4] mr-2" size={16} />
            <span>
              {formatTime(selectedRoute?.departureTime)} - {formatTime(selectedRoute?.arrivalTime)}
            </span>
          </div>
        </div>
      </div>

      {/* Легенда */}
      <div className="flex justify-center space-x-6 p-4 bg-gray-50 rounded-lg">
        <div className="flex items-center space-x-2">
          <div className="w-6 h-6 bg-white border border-gray-200 rounded"></div>
          <span>{t.available}</span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="w-6 h-6 bg-red-100 border border-red-200 rounded"></div>
          <span>{t.occupied}</span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="w-6 h-6 bg-blue-500 border border-blue-600 rounded"></div>
          <span>{t.selected}</span>
        </div>
      </div>

      {/* Seats Grid */}
      {seats.map(section => (
        <div key={section.sectionId} className="space-y-4">
          {section.vehicles.map(vehicle => {
            const type = determineVehicleType(vehicle);
            return (
              <div key={vehicle.number} className="bg-white p-6 rounded-lg shadow-md">
                {renderVehicleHeader(vehicle)}
                
                <div className="mt-6">
                  {type === 'train' ? renderTrainSeats(vehicle) : renderBusSeats(vehicle)}
                </div>
              </div>
            );
          })}
        </div>
      ))}

      {/* Обновляем информацию о выбранных местах */}
      <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t shadow-lg">
        <div className="max-w-4xl mx-auto">
          <div className="flex items-center justify-between">
            <div>
              <p className="font-semibold text-blue-900">
                {t.selectedSeats}: {selectedSeats.length}/{passengers}
              </p>
              <div className="text-sm text-gray-600">
                {selectedSeats.map(seat => (
                  <span key={`${seat.vehicleNumber}-${seat.number}`} className="mr-2">
                    {t.vehicle} {seat.vehicleNumber}, {t.seat} {seat.number}
                  </span>
                ))}
              </div>
            </div>
            <button
              onClick={() => onSeatSelect(selectedSeats)}
              disabled={selectedSeats.length !== parseInt(passengers)}
              className={`
                px-6 py-2 rounded-lg transition-colors
                ${selectedSeats.length === parseInt(passengers)
                  ? 'bg-blue-500 hover:bg-blue-600 text-white cursor-pointer'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'}
              `}
            >
              {selectedSeats.length === parseInt(passengers) 
                ? t.confirm 
                : t.selectSeats}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeatSelection;