// src/utils/translations.js

export const LANGUAGES = {
  EN: 'en',
  RU: 'ru',
  CS: 'cs',
  PL: 'pl',
  UK: 'uk'
};

export const TRANSLATIONS = {
  [LANGUAGES.EN]: {
    // Общие
    title: "Travel that moves you",
    subtitle: "Book train, bus, flight and ferry tickets",
    error: "Error",
    close: "Close",
    loading: "Loading...",
    retry: "Retry",
    
    // Поиск маршрутов
    from: "From",
    to: "To",
    date: "Date",
    search: "Search",
    searchRoutes: "Search Routes",
    enterCity: "Enter city name",
    fillAllFields: "Please fill all fields",
    invalidLocations: "Invalid locations selected",
    loadingLocations: "Loading locations...",
    
    // Пассажиры
    passengers: "passenger",
    passengers_plural: "passengers",
    adult: "adult",
    adults: "adults",
    
    // Маршруты
    popular: "Popular destinations",
    availableRoutes: "Available Routes",
    routeDetails: "Route Details",
    noRoutesFound: "No routes found",
    
    // Время и даты
    departure: "Departure",
    arrival: "Arrival",
    departureTime: "Departure Time", 
    arrivalTime: "Arrival Time",
    duration: "Duration",
    
    // Места
    availableSeats: "Available Seats",
    seatsAvailable: "seats available",
    selectedSeat: "Selected Seat",
    seatNumber: "Seat Number",
    selectSeat: "Select a Seat",
    noSeatsAvailable: "No seats available",
    errorFetchingSeats: "Error fetching available seats",
    
    // Бронирование
    book: "Book",
    bookingForm: "Booking Form",
    bookingStep: "Booking Step",
    bookingConfirmed: "Booking Confirmed!",
    bookingHistory: "Booking History",
    bookingCancelled: "Booking Cancelled",
    cancelBooking: "Cancel Booking",
    cancelBookingError: "Error cancelling booking",
    
    // Детали пассажира
    firstName: "First Name",
    lastName: "Last Name", 
    email: "Email",
    phone: "Phone",
    dateOfBirth: "Date of Birth",
    passengerDetails: "Passenger Details",
    
    // Билет
    ticketId: "Ticket ID",
    price: "Price",
    currency: "CZK",
    
    // Транспорт
    transportType: "Transport Type",
    vehicle: "Vehicle",
    train: "Train",
    bus: "Bus",
    plane: "Plane",
    ferry: "Ferry",
    
    // Навигация
    backToSearch: "Back to search",
    backToResults: "Back to results",
    
    // Фильтры и сортировка
    filterAndSort: "Filter and Sort",
    priceRange: "Price Range",
    min: "Min",
    max: "Max",
    sortBy: "Sort By",
    sortOrder: "Sort Order",
    ascending: "Ascending",
    descending: "Descending",
    apply: "Apply",
    
    // Оплата
    loadingPayment: "Loading payment form...",
    processing: "Processing...",
    payNow: "Pay Now",
    
    // Валидация
    phoneFormat: "Format: +420XXXXXXXXX",
    invalidPhone: "Please enter valid phone number in format +420XXXXXXXXX",
    firstNameRequired: "First name is required",
    lastNameRequired: "Last name is required", 
    invalidEmail: "Please enter valid email address",
    invalidDateOfBirth: "Please enter valid date of birth",
    requiredFields: "Required fields",
    
    // Ошибки
    errorLoadingData: "Error loading data",
    errorFetchingHistory: "Error fetching booking history",
    routeDetailsError: "Error loading route details",
    searchError: "Error performing search",
    filterError: "Error applying filters", 

    cardDetails: "Card Details",
    processingPayment: "Processing payment...",
    authenticating: "Authenticating...",
    paymentSuccess: "Payment successful",
    bannerTitle: 'Travel that moves you',
    bannerSubtitle: 'Book train, bus, flight and ferry tickets',
    
    // Выбор мест
    aisle: "Aisle",
    upperSeats: "Upper seats",
    lowerSeats: "Lower seats",
    occupied: "Occupied",
    available: "Available",
    selected: "Selected",
    driver: "Driver seat",
    emptyVehicle: "Car not available",
    upperSeatsCount: "Upper seats",
    lowerSeatsCount: "Lower seats",
    carNumber: "Car",
    busNumber: "Bus",
    
    // Vehicle services
    wifi: "Wi-Fi",
    airConditioner: "Air Conditioning",
    press: "Press",
    hotDrinks: "Hot Drinks",
    food: "Food Service",
    quietZone: "Quiet Zone",
    entertainment: "Entertainment Portal",
    headphones: "Headphones",
    socket: "Power Socket",
    steward: "Steward",
    wheelchairRamp: "Wheelchair Ramp",
    unknownService: "Additional Service",
    
    // Service classes
    economyClass: "Economy",
    businessClass: "Business",
    firstClass: "First Class",
    ticketSentTo: "Ticket has been sent to",
    resendTicket: "Resend Ticket",
    sending: "Sending...",
    emailResent: "Ticket has been resent successfully",
    emailResendError: "Failed to resend ticket. Please try again",
    ticketReady: "Your ticket is ready",
    ticketSentToEmail: "Ticket details have been sent to your email",
    mainPassenger: 'Main Passenger',
    passenger: 'Passenger',
    addPassenger: 'Add Passenger',
    remove: 'Remove',
    sendIndividualEmails: 'Send tickets to mail',
    selectedSeats: 'Selected seats',
    seat: 'Seat',
    pleaseSelectAllSeats: 'Please select seats for all passengers',
    fillAllRequiredFields: 'Please fill all required fields',
    invalidPhoneFormat: 'Invalid phone number format',
    tooOldDateOfBirth: 'Date of birth is too old',
    pleaseSelectSeats: "Please select seats",
    seatsSelected: "seats selected",
    acceptTermsError: "Please accept the terms and conditions",
    bookingError: "An error occurred during booking",
    seatSelectionError: "Please select seats for all passengers",
    seatsPassengersMismatch: "Number of selected seats must match number of passengers",
    selectSeatsForPassengers: "Please select {count} seats",
    cancelling: 'Cancelling...',
    cancelConfirmation: 'Are you sure you want to cancel this booking? This action cannot be undone.',
    cancelSuccess: 'Booking cancelled successfully',
    cancelError: 'Error cancelling booking',
    refundInfo: 'Refund will be processed within 5-7 business days',
    confirm: "Confirm",
    confirmBooking: "Confirm Booking",
    confirmationEmail: "Confirmation email",
    confirmationSent: "Confirmation has been sent to your email",
    pdfGenerating: "Generating PDF ticket...",
    pdfDownload: "Download PDF ticket",
    emailConfirmation: "Email confirmation",
  },

  [LANGUAGES.RU]: {
    // Общие
    title: "Путешествие, которое вдохновляет",
    subtitle: "Бронирование билетов на поезд, автобус, самолет и паром",
    error: "Ошибка",
    close: "Закрыть",
    loading: "Загрузка...",
    retry: "Повторить",
    
    // Поиск маршрутов
    from: "Откуда",
    to: "Куда",
    date: "Дата",
    search: "Поиск",
    searchRoutes: "Поиск маршрутов",
    enterCity: "Введите название города",
    fillAllFields: "Пожалуйста, заполните все поля",
    invalidLocations: "Выбраны неверные локации",
    loadingLocations: "Загрузка локаций...",
    
    // Пассажиры
    passengers: "пассажир",
    passengers_plural: "пассажиров",
    adult: "взрослый",
    adults: "взрослых",
    
    // Маршруты
    popular: "Популярные направления",
    availableRoutes: "Доступные маршруты",
    routeDetails: "Детали маршрута",
    noRoutesFound: "Маршруты не найдены",
    
    // Время и даты
    departure: "Отправление",
    arrival: "Прибытие",
    departureTime: "Время отправления",
    arrivalTime: "Время прибытия",
    duration: "Длительность",
    
    // Места
    availableSeats: "Доступные места",
    seatsAvailable: "мест доступно",
    selectedSeat: "Выбранное место",
    seatNumber: "Номер места",
    selectSeat: "Выберите место",
    noSeatsAvailable: "Нет доступных мест",
    errorFetchingSeats: "Ошибка при загрузке доступных мест",
    
    // Бронирование
    book: "Забронировать",
    bookingForm: "Форма бронирования",
    bookingStep: "Шаг бронирования",
    bookingConfirmed: "Бронирование подтверждено!",
    bookingHistory: "История бронирований",
    bookingCancelled: "Бронирование отменено",
    cancelBooking: "Отменить бронирование",
    cancelBookingError: "Ошибка при отмене бронирования",
    
    // Детали пассажира
    firstName: "Имя",
    lastName: "Фамилия",
    email: "Электронная почта",
    phone: "Телефон",
    dateOfBirth: "Дата рождения",
    passengerDetails: "Данные пассажира",
    
    // Билет
    ticketId: "Номер билета",
    price: "Цена",
    currency: "CZK",
    
    // Транспорт
    transportType: "Тип транспорта",
    vehicle: "Вагон",
    train: "Поезд",
    bus: "Транспорт",
    plane: "Самолет",
    ferry: "Паром",
    
    // Навигация
    backToSearch: "Вернуться к поиску",
    backToResults: "Вернуться к результатам",
    
    // Фильтры и сортировка
    filterAndSort: "Фильтры и сортировка",
    priceRange: "Диапазон цен",
    min: "Мин",
    max: "Макс",
    sortBy: "Сортировать по",
    sortOrder: "Порядок сортировки",
    ascending: "По возрастанию",
    descending: "По убыванию",
    apply: "Применить",
    
    // Оплата
    loadingPayment: "Загрузка формы оплаты...",
    processing: "Обработка...",
    payNow: "Оплатить",
    
    // Валидация
    phoneFormat: "Формат: +420XXXXXXXXX",
    invalidPhone: "Пожалуйста, введите корректный номер телефона в формате +420XXXXXXXXX",
    firstNameRequired: "Необходимо указать имя",
    lastNameRequired: "Необходимо указать фамилию",
    invalidEmail: "Пожалуйста, введите корректный email адрес",
    invalidDateOfBirth: "Пожалуйста, введите корректную дату рождения",
    requiredFields: "Обязательные поля",
    
    // Ошибки
    errorLoadingData: "Ошибка загрузки данных",
    errorFetchingHistory: "Ошибка при загрузке истории бронирований",
    routeDetailsError: "Ошибка загрузки деталей маршрута",
    searchError: "Ошибка при выполнении поиска",
    filterError: "Ошибка при применении фильтров",

    // Дополнительные переводы
    popularDestinations: "Популярные направления",
    chooseDestination: "Выберите между поездами, автобусами, самолетами и паромами",
    addReturn: "Добавить обратный",
    removeReturn: "Убрать обратный",
    findAccommodation: "Найти жилье",
    transfers: "Пересадки",
    delay: "Задержка",
    class: "Класс",
    availableServices: "Доступные услуги",
    confirm: "Подтвердить",
    bookingSummary: "Сводка бронирования",
    route: "Маршрут",
    printTicket: "Распечатать билет",
    instantPayment: "Мгновенная оплата через RegioJet Pay",
    ticketReady: "Ваш билет готов",
    viewTicket: "Посмотреть билет",
    securePayment: "Безопасная оплата",
    payment_pending: "Оплата обрабатывается",
    payment_failed: "Оплата не удалась",
    payment_cancelled: "Оплата отменена",
    payment_expired: "Срок оплаты истек",  

    cardDetails: "Card Details",
    processingPayment: "Processing payment...",
    authenticating: "Authenticating...",
    paymentSuccess: "Payment successful",  
    bannerTitle: 'Путешествуйте с нами',
    bannerSubtitle: 'Бронируйте билеты на поезд, автобус, самолет и паром',
    
    // Выбор мест
    aisle: "Проход",
    upperSeats: "Верхние места",
    lowerSeats: "Нижние места",
    occupied: "Занято",
    available: "Свободно",
    selected: "Выбрано",
    driver: "Место водителя",
    emptyVehicle: "Вагон недоступен",
    upperSeatsCount: "Верхние места",
    lowerSeatsCount: "Нижние места",
    carNumber: "Вагон",
    busNumber: "Автобус",
    
    // Сервисы в транспорте
    wifi: "Wi-Fi",
    airConditioner: "Кондиционер",
    press: "Пресса",
    hotDrinks: "Горячие напитки",
    food: "Питание",
    quietZone: "Тихое купе",
    entertainment: "Развлекательный портал",
    headphones: "Наушники",
    socket: "Розетка",
    steward: "Стюард",
    wheelchairRamp: "Подъёмник для инвалидных колясок",
    unknownService: "Дополнительный сервис",
    
    // Классы обслуживания
    economyClass: "Эконом",
    businessClass: "Бизнес",
    firstClass: "Первый класс",
    ticketSentTo: "Билет был отправлен на",
    resendTicket: "Отправить билет повторно",
    sending: "Отправка...",
    emailResent: "Билет успешно отправлен повторно",
    emailResendError: "Не удалось отправить билет. Попробуйте еще раз",
    ticketSentToEmail: "Данные билета отправлены вам на почту",
    mainPassenger: 'Основной пассажир',
    passenger: 'Пассажир',
    addPassenger: 'Добавить пассажира',
    remove: 'Удалить',
    sendIndividualEmails: 'Отправить билеты на почту',
    selectedSeats: 'Выбранные места',
    seat: 'Место',
    pleaseSelectAllSeats: 'Пожалуйста, выберите места для всех пассажиров',
    fillAllRequiredFields: 'Пожалуйста, заполните все обязательные поля',
    invalidPhoneFormat: 'Неверный формат номера телефона',
    tooOldDateOfBirth: 'Слишком давняя дата рождения',
    pleaseSelectSeats: "Пожалуйста, выберите места",
    seatsSelected: "мест выбрано",
    selectSeats: "Выберите места",
    acceptTermsError: "Пожалуйста, примите условия и правила",
    bookingError: "Произошла ошибка при бронировании",
    seatSelectionError: "Пожалуйста, выберите места для всех пассажиров",
    seatsPassengersMismatch: "Количество выбранных мест должно соответствовать количеству пассажиров",
    selectSeatsForPassengers: "Пожалуйста, выберите {count} мест",
    cancelling: 'Отмена...',
    cancelConfirmation: 'Вы уверены, что хотите отменить бронирование? Это действие нельзя отменить.',
    cancelSuccess: 'Бронирование успешно отменено',
    cancelError: 'Ошибка при отмене бронирования',
    refundInfo: 'Возврат средств будет обработан в течение 5-7 рабочих дней',
    confirm: "Подтвердить",
    confirmBooking: "Подтвердить бронирование",
    confirmationEmail: "Письмо с подтверждением",
    confirmationSent: "Подтверждение отправлено на вашу почту",
    pdfGenerating: "Создание PDF билета...",
    pdfDownload: "Скачать PDF билет",
    emailConfirmation: "Подтверждение по email",
  },

  [LANGUAGES.CS]: {
    // Общие
    title: "Cestování, které vás pohne",
    subtitle: "Rezervujte si jízdenky na vlak, autobus, letadlo a trajekt",
    error: "Chyba",
    close: "Zavřít",
    loading: "Načítání...",
    retry: "Zkusit znovu",
    
    // Поиск маршрутов
    from: "Odkud",
    to: "Kam",
    date: "Datum",
    search: "Hledat",
    searchRoutes: "Hledat spoje",
    enterCity: "Zadejte název města",
    fillAllFields: "Vyplňte prosím všechna pole",
    invalidLocations: "Neplatné lokace",
    loadingLocations: "Načítání lokalit...",
    
    // Пассажиры
    passengers: "cestující",
    passengers_plural: "cestující",
    adult: "dospělý",
    adults: "dospělí",
    
    // Маршруты
    popular: "Oblíbené destinace",
    availableRoutes: "Dostupné spoje",
    routeDetails: "Detaily spoje",
    noRoutesFound: "Žádné spoje nenalezeny",
    
    // Время и даты
    departure: "Odjezd",
    arrival: "Příjezd",
    departureTime: "Čas odjezdu",
    arrivalTime: "Čas příjezdu",
    duration: "Doba jízdy",
    
    // Места
    availableSeats: "Volná místa",
    seatsAvailable: "volných míst",
    selectedSeat: "Vybrané sedadlo",
    seatNumber: "Číslo sedadla",
    selectSeat: "Vyberte sedadlo",
    noSeatsAvailable: "Žádná volná místa",
    errorFetchingSeats: "Chyba při načítání volných míst",
    
    // Бронирование
    book: "Rezervovat",
    bookingForm: "Rezervační formulář",
    bookingStep: "Krok rezervace",
    bookingConfirmed: "Rezervace potvrzena!",
    bookingHistory: "Historie rezervací",
    bookingCancelled: "Rezervace zrušena",
    cancelBooking: "Zrušit rezervaci",
    cancelBookingError: "Chyba při rušení rezervace",
    
    // Детали пассажира
    firstName: "Jméno",
    lastName: "Příjmení",
    email: "E-mail",
    phone: "Telefon",
    dateOfBirth: "Datum narození",
    passengerDetails: "Údaje o cestujícím",
    
    // Билет
    ticketId: "Číslo jízdenky",
    price: "Cena",
    currency: "Euro",
    
    // Транспорт
    transportType: "Typ dopravy",
    vehicle: "Vozidlo",
    train: "Vlak",
    bus: "Autobus",
    plane: "Letadlo",
    ferry: "Trajekt",
    
    // Навигация
    backToSearch: "Zpět na vyhledávání",
    backToResults: "Zpět na výsledky",
    
    // Фильтры и сортировка
    filterAndSort: "Filtrovat a řadit",
    priceRange: "Cenové rozpětí",
    min: "Min",
    max: "Max",
    sortBy: "Řadit podle",
    sortOrder: "Pořadí řazení",
    ascending: "Vzestupně",
    descending: "Sestupně",
    apply: "Použít",
    
    // Оплата
    loadingPayment: "Načítání platebního formuláře...",
    processing: "Zpracování...",
    payNow: "Zaplatit",
    cardDetails: "Údaje o kartě",
    processingPayment: "Zpracování platby...",
    authenticating: "Ověřování...",
    paymentSuccess: "Platba úspěšná",
    
    // Валидация
    phoneFormat: "Formát: +420XXXXXXXXX",
    invalidPhone: "Zadejte platné telefonní číslo ve formátu +420XXXXXXXXX",
    firstNameRequired: "Jméno je povinné",
    lastNameRequired: "Příjmení je povinné",
    invalidEmail: "Zadejte platnou e-mailovou adresu",
    invalidDateOfBirth: "Zadejte platné datum narození",
    requiredFields: "Povinná pole",
    
    // Баннер
    bannerTitle: 'Cestování, které vás pohne',
    bannerSubtitle: 'Rezervujte si jízdenky na vlak, autobus, letadlo a trajekt',
    
    // Выбор мест
    aisle: "Ulička",
    upperSeats: "Horní sedadla",
    lowerSeats: "Dolní sedadla",
    occupied: "Obsazeno",
    available: "Volné",
    selected: "Vybráno",
    driver: "Sedadlo řidiče",
    emptyVehicle: "Vůz není k dispozici",
    upperSeatsCount: "Horní sedadla",
    lowerSeatsCount: "Dolní sedadla",
    carNumber: "Vůz",
    busNumber: "Autobus",
    
    // Сервисы в транспорте
    wifi: "Wi-Fi",
    airConditioner: "Klimatizace",
    press: "Tisk",
    hotDrinks: "Teplé nápoje",
    food: "Občerstvení",
    quietZone: "Tichá zóna",
    entertainment: "Zábavní portál",
    headphones: "Sluchátka",
    socket: "Elektrická zásuvka",
    steward: "Stevard",
    wheelchairRamp: "Nájezd pro vozíčkáře",
    unknownService: "Další služba",
    
    // Классы обслуживания
    economyClass: "Ekonomická třída",
    businessClass: "Business třída",
    firstClass: "První třída",

    // Email и билеты
    ticketSentTo: "Jízdenka byla odeslána na",
    resendTicket: "Poslat jízdenku znovu",
    sending: "Odesílání...",
    emailResent: "Jízdenka byla úspěšně znovu odeslána",
    emailResendError: "Nepodařilo se odeslat jízdenku. Zkuste to prosím znovu",
    ticketSentToEmail: "Údaje o jízdence byly odeslány na váš e-mail",
    mainPassenger: 'Hlavní cestující',
    passenger: 'Cestující',
    addPassenger: 'Přidat cestujícího',
    remove: 'Odstranit',
    sendIndividualEmails: 'Odesílání vstupenek na mail',
    selectedSeats: 'Vybraná sedadla',
    seat: 'Sedadlo',
    pleaseSelectAllSeats: 'Vyberte prosím sedadla pro všechny cestující',
    fillAllRequiredFields: 'Vyplňte prosím všechna povinná pole',
    invalidPhoneFormat: 'Neplatný formát telefonního čísla',
    tooOldDateOfBirth: 'Příliš staré datum narození',
    pleaseSelectSeats: "Vyberte prosím sedadla",
    seatsSelected: "sedadel vybráno",
    selectSeats: "Vybrat sedadla",
    acceptTermsError: "Přijměte prosím podmínky a pravidla",
    bookingError: "Při rezervaci došlo k chybě",
    seatSelectionError: "Vyberte prosím sedadla pro všechny cestující",
    seatsPassengersMismatch: "Počet vybraných sedadel musí odpovídat počtu cestujících",
    selectSeatsForPassengers: "Vyberte prosím {count} sedadel",
    cancelling: 'Rušení...',
    cancelConfirmation: 'Opravdu chcete zrušit rezervaci? Tuto akci nelze vrátit zpět.',
    cancelSuccess: 'Rezervace byla úspěšně zrušena',
    cancelError: 'Chyba při rušení rezervace',
    refundInfo: 'Vrácení peněz bude zpracováno během 5-7 pracovních dnů',
    confirm: "Potvrdit",
    confirmBooking: "Potvrdit rezervaci",
    confirmationEmail: "Potvrzovací e-mail",
    confirmationSent: "Potvrzení bylo odesláno na váš e-mail",
    pdfGenerating: "Generování PDF jízdenky...",
    pdfDownload: "Stáhnout PDF jízdenku",
    emailConfirmation: "E-mailové potvrzení",
  },

  [LANGUAGES.PL]: {
    // Общие
    title: "Podróże, które Cię poruszają",
    subtitle: "Zarezerwuj bilety na pociąg, autobus, samolot i prom",
    error: "Błąd",
    close: "Zamknij",
    loading: "Ładowanie...",
    retry: "Spróbuj ponownie",
    
    // Поиск маршрутов
    from: "Skąd",
    to: "Dokąd",
    date: "Data",
    search: "Szukaj",
    searchRoutes: "Szukaj połączeń",
    enterCity: "Wpisz nazwę miasta",
    fillAllFields: "Proszę wypełnić wszystkie pola",
    invalidLocations: "Nieprawidłowe lokalizacje",
    loadingLocations: "Ładowanie lokalizacji...",
    
    // Пассажиры
    passengers: "pasażer",
    passengers_plural: "pasażerów",
    adult: "dorosły",
    adults: "dorośli",
    
    // Маршруты
    popular: "Popularne kierunki",
    availableRoutes: "Dostępne połączenia",
    routeDetails: "Szczegóły połączenia",
    noRoutesFound: "Nie znaleziono połączeń",
    
    // Время и даты
    departure: "Odjazd",
    arrival: "Przyjazd",
    departureTime: "Czas odjazdu",
    arrivalTime: "Czas przyjazdu",
    duration: "Czas podróży",
    
    // Места
    availableSeats: "Dostępne miejsca",
    seatsAvailable: "wolnych miejsc",
    selectedSeat: "Wybrane miejsce",
    seatNumber: "Numer miejsca",
    selectSeat: "Wybierz miejsce",
    noSeatsAvailable: "Brak dostępnych miejsc",
    errorFetchingSeats: "Błąd podczas pobierania dostępnych miejsc",
    
    // Бронирование
    book: "Zarezerwuj",
    bookingForm: "Formularz rezerwacji",
    bookingStep: "Krok rezervacji",
    bookingConfirmed: "Rezerwacja potwierdzona!",
    bookingHistory: "Historia rezerwacji",
    bookingCancelled: "Rezerwacja anulowana",
    cancelBooking: "Anuluj rezerwację",
    cancelBookingError: "Błąd podczas anulowania rezerwacji",
    
    // Детали пассажира
    firstName: "Imię",
    lastName: "Nazwisko",
    email: "E-mail",
    phone: "Telefon",
    dateOfBirth: "Data urodzenia",
    passengerDetails: "Dane pasażera",
    
    // Билет
    ticketId: "Numer biletu",
    price: "Cena",
    currency: "Euro",
    
    // Транспорт
    transportType: "Rodzaj transportu",
    vehicle: "Pojazd",
    train: "Pociąg",
    bus: "Autobus",
    plane: "Samolot",
    ferry: "Prom",
    
    // Навигация
    backToSearch: "Powrót do wyszukiwania",
    backToResults: "Powrót do wyników",
    
    // Фильтры и сортировка
    filterAndSort: "Filtruj i sortuj",
    priceRange: "Zakres cen",
    min: "Min",
    max: "Max",
    sortBy: "Sortuj według",
    sortOrder: "Kolejność sortowania",
    ascending: "Rosnąco",
    descending: "Malejąco",
    apply: "Zastosuj",
    
    // Оплата
    loadingPayment: "Ładowanie formularza płatności...",
    processing: "Przetwarzanie...",
    payNow: "Zapłać teraz",
    cardDetails: "Dane karty",
    processingPayment: "Przetwarzanie płatności...",
    authenticating: "Uwierzytelnianie...",
    paymentSuccess: "Płatność zakończona sukcesem",
    
    // Валидация
    phoneFormat: "Format: +48XXXXXXXXX",
    invalidPhone: "Wprowadź prawidłowy numer telefonu w formacie +48XXXXXXXXX",
    firstNameRequired: "Imię jest wymagane",
    lastNameRequired: "Nazwisko jest wymagane",
    invalidEmail: "Wprowadź prawidłowy adres e-mail",
    invalidDateOfBirth: "Wprowadź prawidłową datę urodzenia",
    requiredFields: "Pola wymagane",
    
    // Баннер
    bannerTitle: 'Podróże, które Cię poruszają',
    bannerSubtitle: 'Zarezerwuj bilety na pociąg, autobus, samolot i prom',
    
    // Выбор мест
    aisle: "Przejście",
    upperSeats: "Miejsca górne",
    lowerSeats: "Miejsca dolne",
    occupied: "Zajęte",
    available: "Wolne",
    selected: "Wybrane",
    driver: "Miejsce kierowcy",
    emptyVehicle: "Pojazd niedostępny",
    upperSeatsCount: "Miejsca górne",
    lowerSeatsCount: "Miejsca dolne",
    carNumber: "Wagon",
    busNumber: "Autobus",
    
    // Сервисы в транспорте
    wifi: "Wi-Fi",
    airConditioner: "Klimatyzacja",
    press: "Prasa",
    hotDrinks: "Gorące napoje",
    food: "Przekąski",
    quietZone: "Strefa ciszy",
    entertainment: "Portal rozrywkowy",
    headphones: "Słuchawki",
    socket: "Gniazdko elektryczne",
    steward: "Steward",
    wheelchairRamp: "Rampa dla wózków",
    unknownService: "Dodatkowa usługa",
    
    // Классы обслуживания
    economyClass: "Klasa ekonomiczna",
    businessClass: "Klasa biznesowa",
    firstClass: "Pierwsza klasa",

    // Email и билеты
    ticketSentTo: "Bilet został wysłany na",
    resendTicket: "Wyślij bilet ponownie",
    sending: "Wysyłanie...",
    emailResent: "Bilet został pomyślnie wysłany ponownie",
    emailResendError: "Nie udało się wysłać biletu. Spróbuj ponownie",
    ticketSentToEmail: "Dane biletu zostały wysłane na Twój e-mail",
    mainPassenger: 'Główny pasażer',
    passenger: 'Pasażer',
    addPassenger: 'Dodaj pasażera',
    remove: 'Usuń',
    sendIndividualEmails: 'Wyślij bilety pocztą',
    selectedSeats: 'Wybrane miejsca',
    seat: 'Miejsce',
    pleaseSelectAllSeats: 'Wybierz miejsca dla wszystkich pasażerów',
    fillAllRequiredFields: 'Wypełnij wszystkie wymagane pola',
    invalidPhoneFormat: 'Nieprawidłowy format numeru telefonu',
    tooOldDateOfBirth: 'Zbyt wczesna data urodzenia',
    pleaseSelectSeats: "Wybierz miejsca",
    seatsSelected: "wybranych miejsc",
    selectSeats: "Wybierz miejsca",
    acceptTermsError: "Zaakceptuj warunki i zasady",
    bookingError: "Wystąpił błąd podczas rezerwacji",
    seatSelectionError: "Wybierz miejsca dla wszystkich pasażerów",
    seatsPassengersMismatch: "Liczba wybraných miejsc musi odpowiadać liczbie pasażerów",
    selectSeatsForPassengers: "Wybierz {count} miejsc",
    cancelling: 'Anulowanie...',
    cancelConfirmation: 'Czy na pewno chcesz anulować rezerwację? Tej akcji nie można cofnąć.',
    cancelSuccess: 'Rezerwacja została pomyślnie anulowana',
    cancelError: 'Błąd podczas anulowania rezerwacji',
    refundInfo: 'Zwrot pieniędzy zostanie przetworzony w ciągu 5-7 dni roboczych',
    confirm: "Potwierdź",
    confirmBooking: "Potwierdź rezerwację",
    confirmationEmail: "E-mail potwierdzający",
    confirmationSent: "Potwierdzenie zostało wysłane na twój e-mail",
    pdfGenerating: "Generowanie biletu PDF...",
    pdfDownload: "Pobierz bilet PDF",
    emailConfirmation: "Potwierdzenie e-mail",
  },

  [LANGUAGES.UK]: {
    // Общие
    title: "Подорожі, що надихають",
    subtitle: "Бронюйте квитки на потяг, автобус, літак та пором",
    error: "Помилка",
    close: "Закрити",
    loading: "Завантаження...",
    retry: "Спробувати ще раз",
    
    // Поиск маршрутов
    from: "Звідки",
    to: "Куди",
    date: "Дата",
    search: "Пошук",
    searchRoutes: "Пошук маршрутів",
    enterCity: "Введіть назву міста",
    fillAllFields: "Будь ласка, заповніть усі поля",
    invalidLocations: "Неправильні локації",
    loadingLocations: "Завантаження локацій...",
    
    // Пассажиры
    passengers: "пасажир",
    passengers_plural: "пасажирів",
    adult: "дорослий",
    adults: "дорослі",
    
    // Маршруты
    popular: "Популярні напрямки",
    availableRoutes: "Доступні маршрути",
    routeDetails: "Деталі маршруту",
    noRoutesFound: "Маршрути не знайдено",
    
    // Время и даты
    departure: "Відправлення",
    arrival: "Прибуття",
    departureTime: "Час відправлення",
    arrivalTime: "Час прибуття",
    duration: "Тривалість",
    
    // Места
    availableSeats: "Вільні місця",
    seatsAvailable: "вільних місць",
    selectedSeat: "Вибране місце",
    seatNumber: "Номер місця",
    selectSeat: "Виберіть місце",
    noSeatsAvailable: "Немає вільних місць",
    errorFetchingSeats: "Помилка при завантаженні вільних місць",
    
    // Бронирование
    book: "Забронювати",
    bookingForm: "Форма бронювання",
    bookingStep: "Крок бронювання",
    bookingConfirmed: "Бронювання підтверджено!",
    bookingHistory: "Історія бронювань",
    bookingCancelled: "Бронювання скасовано",
    cancelBooking: "Скасувати бронювання",
    cancelBookingError: "Помилка при скасуванні бронювання",
    
    // Детали пассажира
    firstName: "Ім'я",
    lastName: "Прізвище",
    email: "Email",
    phone: "Телефон",
    dateOfBirth: "Дата народження",
    passengerDetails: "Дані пасажира",
    
    // Билет
    ticketId: "Номер квитка",
    price: "Ціна",
    currency: "Euro",
    
    // Транспорт
    transportType: "Тип транспорту",
    vehicle: "Транспорт",
    train: "Потяг",
    bus: "Автобус",
    plane: "Літак",
    ferry: "Пором",
    
    // Навигация
    backToSearch: "Назад до пошуку",
    backToResults: "Назад до результатів",
    
    // Фильтры и сортировка
    filterAndSort: "Фільтр та сортування",
    priceRange: "Діапазон цін",
    min: "Мін",
    max: "Макс",
    sortBy: "Сортувати за",
    sortOrder: "Порядок сортування",
    ascending: "За зростанням",
    descending: "За спаданням",
    apply: "Застосувати",
    
    // Оплата
    loadingPayment: "Завантаження форми оплати...",
    processing: "Обробка...",
    payNow: "Оплатити зараз",
    cardDetails: "Дані картки",
    processingPayment: "Обробка платежу...",
    authenticating: "Автентифікація...",
    paymentSuccess: "Оплату успішно здійснено",
    
    // Валидация
    phoneFormat: "Формат: +380XXXXXXXXX",
    invalidPhone: "Будь ласка, введіть правильний номер телефону у форматі +380XXXXXXXXX",
    firstNameRequired: "Ім'я обов'язкове",
    lastNameRequired: "Прізвище обов'язкове",
    invalidEmail: "Будь ласка, введіть правильну email адресу",
    invalidDateOfBirth: "Будь ласка, введіть правильну дату народження",
    requiredFields: "Обов'язкові поля",
    
    // Баннер
    bannerTitle: 'Подорожі, що надихають',
    bannerSubtitle: 'Бронюйте квитки на потяг, автобус, літак та пором',
    
    // Выбор мест
    aisle: "Прохід",
    upperSeats: "Верхні місця",
    lowerSeats: "Нижні місця",
    occupied: "Зайнято",
    available: "Вільно",
    selected: "Вибрано",
    driver: "Місце водія",
    emptyVehicle: "Транспорт недоступний",
    upperSeatsCount: "Верхні місця",
    lowerSeatsCount: "Нижні місця",
    carNumber: "Вагон",
    busNumber: "Автобус",
    
    // Сервисы в транспорте
    wifi: "Wi-Fi",
    airConditioner: "Кондиціонер",
    press: "Преса",
    hotDrinks: "Горячі напої",
    food: "Харчування",
    quietZone: "Тиха зона",
    entertainment: "Розважальний портал",
    headphones: "Навушники",
    socket: "Розетка",
    steward: "Стюард",
    wheelchairRamp: "Підйомник для інвалідних візків",
    unknownService: "Додатковий сервіс",
    
    // Классы обслуживания
    economyClass: "Економ клас",
    businessClass: "Бізнес клас",
    firstClass: "Перший клас",

    // Email и билеты
    ticketSentTo: "Квиток надіслано на",
    resendTicket: "Надіслати квиток повторно",
    sending: "Надсилання...",
    emailResent: "Квиток успішно надіслано повторно",
    emailResendError: "Не вдалося надіслати квиток. Спробуйте ще раз",
    ticketSentToEmail: "Дані квитка надіслано на вашу пошту",
    mainPassenger: 'Головний пасажир',
    passenger: 'Пасажир',
    addPassenger: 'Додати пасажира',
    remove: 'Видалити',
    sendIndividualEmails: 'Надсилайте квитки на електронну пошту',
    selectedSeats: 'Вибрані місця',
    seat: 'Місце',
    pleaseSelectAllSeats: 'Будь ласка, виберіть місця для всіх пасажирів',
    fillAllRequiredFields: 'Будь ласка, заповніть усі обов\'язкові поля',
    invalidPhoneFormat: 'Неправильний формат номера телефону',
    tooOldDateOfBirth: 'Занадто давня дата народження',
    pleaseSelectSeats: "Будь ласка, виберіть місця",
    seatsSelected: "місць вибрано",
    selectSeats: "Виберіть місця",
    acceptTermsError: "Будь ласка, прийміть умови та правила",
    bookingError: "Виникла помилка при бронюванні",
    seatSelectionError: "Будь ласка, виберіть місця для всіх пасажирів",
    seatsPassengersMismatch: "Кількість вибраних місць має відповідати кількості пасажирів",
    selectSeatsForPassengers: "Будь ласка, виберіть {count} місць",
    cancelling: 'Скасування...',
    cancelConfirmation: 'Ви впевнені, що хочете скасувати бронювання? Цю дію не можна відмінити.',
    cancelSuccess: 'Бронювання успішно скасовано',
    cancelError: 'Помилка при скасуванні бронювання',
    refundInfo: 'Повернення коштів буде оброблено протягом 5-7 робочих днів',
    confirm: "Підтвердити",
    confirmBooking: "Підтвердити бронювання",
    confirmationEmail: "Лист підтвердження",
    confirmationSent: "Підтвердження надіслано на вашу пошту",
    pdfGenerating: "Створення PDF квитка...",
    pdfDownload: "Завантажити PDF квиток",
    emailConfirmation: "Підтвердження електронною поштою",
  }
};