import BookingHistory from '../components/BookingHistory';

function Profile() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Мой профиль</h1>
      
      {/* Передаем email пользователя */}
      <BookingHistory email="test@example.com" />
    </div>
  );
}

export default Profile; 