class EmailService {
  async sendTicket(ticketData) {
    try {
      const response = await fetch('/api/email/send-ticket', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ticketData)
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to send ticket');
      }

      return await response.json();
    } catch (error) {
      console.error('Error sending ticket:', error);
      throw error;
    }
  }
}

export const emailService = new EmailService(); 