// src/components/BookingHistory.js

import React, { useState, useEffect } from 'react';
import { Clock, MapPin, CreditCard } from 'lucide-react';
import api from '../services/regiojet-api';
import { format } from 'date-fns';

const BookingHistory = ({ email }) => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        console.log('Fetching bookings for:', email);
        const response = await fetch(`/api/bookings/history?email=${email}`);
        console.log('Response:', response);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('Received data:', data);
        
        setBookings(data.bookings);
      } catch (error) {
        console.error('Error fetching bookings:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (email) {
      fetchBookings();
    }
  }, [email]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!bookings.length) return <div>No bookings found</div>;

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold">Booking History</h2>
      {bookings.map(booking => (
        <div key={booking.id} className="border p-4 rounded-lg">
          <div className="flex justify-between">
            <div>
              <p className="font-semibold">
                {booking.departure_station} → {booking.arrival_station}
              </p>
              <p className="text-sm text-gray-600">
                {format(new Date(booking.departure_time), 'PPP HH:mm')}
              </p>
            </div>
            <div className="text-right">
              <p className="font-semibold">
                {booking.price} {booking.currency}
              </p>
              <p className={`text-sm ${
                booking.status === 'PAID' ? 'text-green-600' : 'text-yellow-600'
              }`}>
                {booking.status}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BookingHistory;