/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { AlertCircle } from 'lucide-react';
import { Toaster, toast } from 'react-hot-toast';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';

// Components
import SearchForm from './components/SearchForm';
import RouteList from './components/RouteList';
import RouteDetails from './components/RouteDetails';
import SeatSelection from './components/SeatSelection';
import BookingForm from './components/BookingForm';
import BookingConfirmation from './components/BookingConfirmation';
import PaymentForm from './components/PaymentForm';
import LanguageSelector from './components/LanguageSelector';
import PopularDestinations from './components/PopularDestinations';
import LoadingScreen from './components/LoadingScreen';
import FilterSort from './components/FilterSort';
import CancelTicket from './pages/CancelTicket';
import Profile from './pages/Profile';

// Services & Utils
import api from './services/regiojet-api';
import { LANGUAGES, TRANSLATIONS } from './utils/translations';

const App = () => {
  // State declarations
  const [view, setView] = useState('search');
  const [routes, setRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState(LANGUAGES.RU);
  const [bookingStep, setBookingStep] = useState('route');
  const [showPayment, setShowPayment] = useState(false);
  const [passengers, setPassengers] = useState(1);
  
  // Ref for transitions
  const nodeRef = useRef(null);

  // Get translations
  const t = TRANSLATIONS[language];

  // Initial data loading
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setLoading(true);
        const [locationsData, tariffsData, seatClassesData] = await Promise.all([
          api.getLocations().catch(error => {
            console.error('Error loading locations:', error);
            return [];
          }),
          api.getTariffs().catch(error => {
            console.error('Error loading tariffs:', error);
            return [];
          }),
          api.getSeatClasses().catch(error => {
            console.error('Error loading seat classes:', error);
            return [];
          })
        ]);

        console.log('Initial data loaded:', {
          locations: locationsData.length,
          tariffs: tariffsData.length,
          seatClasses: seatClassesData.length
        });
      } catch (err) {
        console.error('Error loading initial data:', err);
        setError(t.errorLoadingData);
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, [t]);

  // Reset all states to initial
  const resetState = () => {
    setSelectedRoute(null);
    setSelectedSeat(null);
    setBooking(null);
    setShowPayment(false);
    setBookingStep('route');
    setError(null);
    setPassengers(1);
  };

  // Handler for payment completion
  const handlePaymentComplete = async (paymentResult) => {
    try {
      if (!booking?.id) {
        throw new Error('Invalid booking data');
      }
  
      setBooking(prev => ({
        ...prev,
        status: 'PAID'
      }));
  
      setShowPayment(false);
      setView('confirmation');
    } catch (error) {
      console.error('Payment completion error:', error);
      setError(t.paymentCompletionError || 'Failed to complete payment');
    }
  };

  // Handler for seat selection
  const handleSeatSelect = (seats) => {
    if (!seats || seats.length === 0) {
      setError(t.seatSelectionError);
      return;
    }

    // Проверяем соответствие количества мест количеству пассажиров
    if (seats.length !== Number(passengers)) {
      setError(t.seatsPassengersMismatch);
      return;
    }

    console.log('Selected seats:', seats);
    setSelectedSeat(seats);
    setBookingStep('details');
  };

  // Handler for route selection
  const handleRouteSelect = async (route) => {
    setLoading(true);
    setError(null);
    try {
      if (!route?.id) {
        throw new Error('Invalid route selected');
      }

      const routeDetails = await api.getRouteDetails({
        routeId: route.id,
        fromStationId: route.departureStationId,
        toStationId: route.arrivalStationId,
        departureDate: route.departureTime
      });

      if (!routeDetails) {
        throw new Error('Failed to fetch route details');
      }

      const formattedRoute = {
        ...routeDetails,
        id: route.id || routeDetails.id,
        departureStationId: route.departureStationId || routeDetails.departureStationId,
        arrivalStationId: route.arrivalStationId || routeDetails.arrivalStationId,
        departureTime: route.departureTime || routeDetails.departureTime,
        arrivalTime: route.arrivalTime || routeDetails.arrivalTime,
        price: route.price || routeDetails.priceFrom,
        currency: route.currency || routeDetails.currency || 'EUR',
        seatClass: routeDetails.priceClasses?.[0]?.seatClassKey || 'REGULAR',
        mainSectionId: routeDetails.mainSectionId,
        priceSource: routeDetails.priceClasses?.[0]?.priceSource,
        sections: [{
          sectionId: routeDetails.mainSectionId,
          fromStationId: route.departureStationId,
          toStationId: route.arrivalStationId
        }]
      };

      setSelectedRoute(formattedRoute);
      setBookingStep('seat');
      setView('booking');
    } catch (error) {
      console.error('Route selection error:', error);
      setError(t.routeDetailsError);
    } finally {
      setLoading(false);
    }
  };

  // Handler for search
  const handleSearch = async (searchParams) => {
    setLoading(true);
    setError(null);
    try {
      if (!searchParams?.fromLocationId || !searchParams?.toLocationId) {
        throw new Error('Invalid search parameters');
      }

      const routesData = await api.searchRoutes(searchParams);
      
      if (!routesData?.routes) {
        throw new Error('No routes found');
      }

      setRoutes(routesData.routes);
      setFilteredRoutes(routesData.routes);
      setView('results');
    } catch (error) {
      console.error('Search error:', error);
      setError(t.searchError);
    } finally {
      setLoading(false);
    }
  };

  // Handler for booking
  const handleBooking = async (bookingData) => {
    try {
      if (!selectedRoute?.id || !selectedSeat || selectedSeat.length === 0) {
        throw new Error('Missing route or seat data');
      }

      console.log('Selected route:', selectedRoute);

      const ticketRequest = {
        routeId: selectedRoute.id,
        selectedRoute: {
          ...selectedRoute,
          departureStationId: selectedRoute.departureStationId,
          arrivalStationId: selectedRoute.arrivalStationId,
          priceSource: selectedRoute.priceSource,
          seatClass: selectedRoute.seatClass || 'BUS_STANDARD'
        },
        selectedSeats: selectedSeat.map(seat => ({
          ...seat,
          number: seat.number,
          vehicleNumber: seat.vehicleNumber
        })),
        passengers: bookingData.passengers
      };

      console.log('Ticket request:', ticketRequest);

      const { ticket, token } = await api.createTicket(ticketRequest);

      if (!ticket || !token) {
        throw new Error('Invalid ticket data received');
      }

      const totalPrice = Math.round(parseFloat(selectedRoute.price) * passengers * 100) / 100;
      const mainPassengerEmail = bookingData.passengers[0]?.email?.trim();

      // Создаем объект бронирования
      const bookingObj = {
        id: ticket.id,
        token: token,
        price: totalPrice,
        currency: 'eur',
        email: mainPassengerEmail,
        status: ticket.status || 'PENDING',
        routeId: selectedRoute.id,
        departureStation: selectedRoute.departureStationId,
        arrivalStation: selectedRoute.arrivalStationId,
        departureTime: selectedRoute.departureTime,
        arrivalTime: selectedRoute.arrivalTime,
        seatNumbers: selectedSeat.map(seat => seat.number),
        vehicleNumbers: selectedSeat.map(seat => seat.vehicleNumber),
        passengers: passengers,
        passengerEmails: bookingData.passengers.map(p => p.email?.trim()).filter(Boolean),
        bookingToken: token,
        accountCode: bookingData.passengers[0]?.accountCode
      };

      // Обогащаем данные бронирования
      const enrichedBooking = {
        ...bookingObj,
        departureStationName: selectedRoute.departureStationName,
        arrivalStationName: selectedRoute.arrivalStationName,
        firstName: bookingData.passengers[0]?.firstName,
        lastName: bookingData.passengers[0]?.surname || bookingData.passengers[0]?.lastName,
        surname: bookingData.passengers[0]?.surname,
        seatNumber: selectedSeat[0]?.number,
        vehicleNumber: selectedSeat[0]?.vehicleNumber,
        passengersInfo: {
          passengers: bookingData.passengers.map(p => ({
            firstName: p.firstName,
            surname: p.surname,
            lastName: p.surname,
            email: p.email,
            phone: p.phone,
            dateOfBirth: p.dateOfBirth
          }))
        }
      };

      // Добавляем отладочный вывод
      console.log('Passenger data from bookingData:', {
        passenger: bookingData.passengers[0],
        enrichedData: {
          firstName: enrichedBooking.firstName,
          lastName: enrichedBooking.lastName,
          surname: enrichedBooking.surname,
          passengerInfo: enrichedBooking.passengersInfo?.passengers?.[0]
        }
      });

      setBooking(enrichedBooking);

      console.log('Booking created:', {
        id: ticket.id,
        price: totalPrice,
        currency: 'eur',
        email: mainPassengerEmail,
        passengers: passengers,
        allEmails: bookingData.passengers.map(p => p.email?.trim()).filter(Boolean)
      });

      // Отправляем email для каждого пассажира
      try {
        const emailPromises = bookingData.passengers.map(async (passenger) => {
          if (passenger.email) {
            const emailData = {
              ticketId: ticket.id,
              email: passenger.email,
              token: token,
              language: language,
              firstName: passenger.firstName,
              lastName: passenger.surname || passenger.lastName,
              departureStation: selectedRoute.departureStationName,
              arrivalStation: selectedRoute.arrivalStationName,
              departureTime: selectedRoute.departureTime,
              arrivalTime: selectedRoute.arrivalTime,
              price: totalPrice,
              currency: selectedRoute.currency || 'EUR',
              seatNumber: selectedSeat[0]?.number,
              vehicleNumber: selectedSeat[0]?.vehicleNumber
            };

            // Добавляем отладочный вывод
            console.log('Email data preparation:', {
              passengerData: {
                firstName: passenger.firstName,
                surname: passenger.surname,
                lastName: passenger.lastName
              },
              emailData: {
                firstName: emailData.firstName,
                lastName: emailData.lastName
              }
            });

            console.log('Sending ticket email:', emailData);

            const response = await fetch('/api/tickets/send-email', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(emailData)
            });

            if (!response.ok) {
              throw new Error('Failed to send email: ' + await response.text());
            }
          }
        });

        await Promise.all(emailPromises);
      } catch (error) {
        console.error('Error sending emails:', error);
        // Продолжаем выполнение даже при ошибке отправки email
      }

      // Сохраняем бронирование в базе данных
      await api.saveBooking(enrichedBooking);

      setShowPayment(true);
      setView('payment');
    } catch (error) {
      console.error('Booking error:', error);
      setError(t.bookingError);
    }
  };

  // Handler for booking cancellation
  const handleCancellation = async (booking) => {
    try {
      if (!booking?.ticketId || !booking?.accountCode) {
        console.error('Invalid booking data:', booking);
        throw new Error(t.invalidBookingData);
      }

      // Показываем индикатор загрузки
      setLoading(true);

      // 1. Сначала отменяем билет в RegioJet
      const ticketResponse = await fetch(`/api/regiojet/tickets/${booking.accountCode}/${booking.ticketId}`, {
        headers: {
          'X-Auth-Token': booking.token || localStorage.getItem('bookingToken'),
          'X-Application-Origin': 'AFF',
          'X-Lang': language,
          'Accept': 'application/json'
        }
      });

      if (!ticketResponse.ok) {
        throw new Error(t.ticketCheckError);
      }

      const ticketInfo = await ticketResponse.json();
      
      // Проверяем возможность отмены и возврата средств
      if (ticketInfo.state === 'VALID' && 
          ticketInfo.conditions?.refundToOriginalSourcePossible && 
          (ticketInfo.customerActions?.cancel || ticketInfo.customerActions?.storno)) {
        
        // 2. Отменяем билет
        const cancelResponse = await fetch(`/api/regiojet/tickets/${booking.accountCode}/${booking.ticketId}/cancel`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': booking.token || localStorage.getItem('bookingToken'),
            'X-Application-Origin': 'AFF',
            'X-Lang': language
          },
          body: JSON.stringify({
            controlHash: ticketInfo.conditions.code,
            refundToOriginalSource: true
          })
        });

        if (!cancelResponse.ok) {
          throw new Error(t.ticketCancellationError);
        }

        const cancelResult = await cancelResponse.json();

        // 3. Обновляем состояние после успешной отмены
        setBooking(prev => ({
          ...prev,
          status: 'CANCELLED',
          state: 'CANCELED',
          cancelledAt: new Date().toISOString()
        }));

        // Показываем уведомление об успехе
        toast.success(t.cancelSuccess);

        return true; // Возвращаем true для подтверждения успешной отмены

      } else {
        throw new Error(t.ticketNotCancellable);
      }

    } catch (error) {
      console.error('Cancellation error:', error);
      toast.error(error.message || t.cancelAndRefundError);
      throw error; // Пробрасываем ошибку дальше
    } finally {
      setLoading(false);
    }
  };

  // Handler for filter/sort
  const handleFilterSort = ({ priceRange, sortBy, sortOrder }) => {
    try {
      let filtered = [...routes];

      // Apply price filter
      if (priceRange.min || priceRange.max) {
        filtered = filtered.filter(route => {
          const price = Number(route.price);
          const min = Number(priceRange.min) || 0;
          const max = Number(priceRange.max) || Infinity;
          return price >= min && price <= max;
        });
      }

      // Apply sorting
      filtered.sort((a, b) => {
        let comparison = 0;
        switch (sortBy) {
          case 'price':
            comparison = Number(a.price) - Number(b.price);
            break;
          case 'departureTime':
            comparison = new Date(a.departureTime) - new Date(b.departureTime);
            break;
          case 'arrivalTime':
            comparison = new Date(a.arrivalTime) - new Date(b.arrivalTime);
            break;
          default:
            comparison = 0;
        }
        return sortOrder === 'desc' ? -comparison : comparison;
      });

      setFilteredRoutes(filtered);
    } catch (error) {
      console.error('Filter/sort error:', error);
      setError(t.filterError);
    }
  };

  // Добавляем эффект для проверки URL при загрузке
  useEffect(() => {
    const checkPaymentStatus = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const paymentIntentId = urlParams.get('payment_intent');
      const redirectStatus = urlParams.get('redirect_status');

      if (paymentIntentId && redirectStatus) {
        try {
          const response = await fetch(`/api/stripe/payment-intent/${paymentIntentId}`);
          const paymentData = await response.json();

          if (paymentData.status === 'succeeded') {
            // Обновляем статус бронирования
            setBooking(prev => ({
              ...prev,
              status: 'PAID',
              paymentId: paymentIntentId
            }));
            setView('confirmation');
          } else if (paymentData.status === 'failed') {
            setError(t.paymentFailed);
            setView('payment');
          }
        } catch (error) {
          console.error('Error checking payment status:', error);
          setError(t.errorCheckingPayment);
        }
      }
    };

    checkPaymentStatus();
  }, []);

  const handlePaymentSuccess = async (paymentData) => {
    try {
      if (!booking) {
        throw new Error('Booking data is missing');
      }

      // Получаем токен из ответа аутентификации
      const token = paymentData.bookingToken || paymentData.token;

      // Обновляем состояние с проверкой данных
      setBooking({
        ...booking,
        id: paymentData.id,
        ticketId: paymentData.id,
        accountCode: paymentData.accountCode,
        token: token,
        status: 'PAID',
        paymentId: paymentData.id,
        paymentIntentId: paymentData.paymentIntentId,
        departureStation: booking.departureStation || booking.fromStation,
        arrivalStation: booking.arrivalStation || booking.toStation,
        price: booking.price,
        currency: booking.currency,
        email: booking.email,
        passengers: booking.passengers,
        seatNumbers: booking.seatNumbers,
        vehicleNumbers: booking.vehicleNumbers,
        passengerEmails: booking.passengerEmails,
        departureTime: booking.departureTime,
        arrivalTime: booking.arrivalTime,
        departureStationName: booking.departureStationName,
        arrivalStationName: booking.arrivalStationName,
        firstName: booking.firstName,
        lastName: booking.lastName,
        surname: booking.surname,
        seatNumber: booking.seatNumber,
        vehicleNumber: booking.vehicleNumber,
        passengersInfo: booking.passengersInfo
      });

      // Сохраняем токен в localStorage
      if (token) {
        localStorage.setItem('bookingToken', token);
      }

      setView('confirmation');
      setShowPayment(false);

    } catch (error) {
      console.error('Payment success handler error:', error);
      setError(t.paymentProcessingError);
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <div className="min-h-screen bg-gray-100">
            <Toaster 
              position="top-center"
              toastOptions={{
                duration: 4000,
                style: {
                  background: '#363636',
                  color: '#fff',
                },
                success: {
                  iconTheme: {
                    primary: '#22c55e',
                    secondary: '#fff',
                  },
                },
                error: {
                  iconTheme: {
                    primary: '#ef4444',
                    secondary: '#fff',
                  },
                },
              }}
            />

            {/* Language selector */}
            <div className="absolute top-4 right-4 z-10">
              <LanguageSelector
                currentLanguage={language}
                onLanguageChange={setLanguage}
              />
            </div>

            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
              {/* Центрируем логотип */}
              <Link 
                to="/" 
                className="flex justify-center mb-8 cursor-pointer"
                onClick={() => {
                  setView('search');
                  setRoutes([]);
                  setSelectedRoute(null);
                  setSelectedSeat(null);
                  setBooking(null);
                }}
              >
                <img 
                  src="/images/logo.png" 
                  alt="RegioJet" 
                  className="h-12 w-auto"
                />
              </Link>

              {/* Main content */}
              <SwitchTransition mode="out-in">
                <CSSTransition
                  key={view}
                  nodeRef={nodeRef}
                  timeout={300}
                  classNames="fade"
                  unmountOnExit
                >
                  <div ref={nodeRef}>
                    {view === 'search' && (
                      <div>
                        <SearchForm 
                          onSearch={handleSearch} 
                          t={t} 
                          passengers={passengers}
                          onPassengersChange={setPassengers}
                        />
                        <PopularDestinations t={t} />
                      </div>
                    )}

                    {view === 'results' && (
                      <div>
                        <button
                          onClick={() => setView('search')}
                          className="mb-4 text-[#9a55f4] hover:text-[#8645e0] flex items-center"
                        >
                          ← {t.backToSearch}
                        </button>
                        <FilterSort onFilterSort={handleFilterSort} t={t} />
                        <RouteList
                          routes={filteredRoutes}
                          onRouteSelect={handleRouteSelect}
                          t={t}
                        />
                      </div>
                    )}

                    {view === 'booking' && selectedRoute && (
                      <div>
                        <button
                          onClick={() => setView('results')}
                          className="mb-4 text-[#9a55f4] hover:text-[#8645e0] flex items-center"
                        >
                          ← {t.backToResults}
                        </button>

                        <RouteDetails route={selectedRoute} t={t} />

                        {bookingStep === 'seat' && (
                          <SeatSelection
                            routeId={selectedRoute?.id}
                            selectedRoute={selectedRoute}
                            onSeatSelect={handleSeatSelect}
                            passengers={Number(passengers)}
                            t={t}
                          />
                        )}

                        {bookingStep === 'details' && (
                          <BookingForm
                            onBook={handleBooking}
                            route={selectedRoute}
                            seats={selectedSeat}
                            passengers={Number(passengers)}
                            t={t}
                          />
                        )}
                      </div>
                    )}

                    {view === 'confirmation' && booking && (
                      <BookingConfirmation
                        booking={booking}
                        onClose={() => {
                          resetState();
                          setView('search');
                        }}
                        onCancel={handleCancellation}
                        t={t}
                      />
                    )}

                    {view === 'payment' && showPayment && booking && (
                      <PaymentForm 
                        booking={booking}
                        ticketPrice={booking.price || booking.tickets?.[0]?.price}
                        onPaymentSuccess={handlePaymentSuccess}
                      />
                    )}
                  </div>
                </CSSTransition>
              </SwitchTransition>

              {/* Loading overlay */}
              {loading && <LoadingScreen t={t} />}

              {/* Error modal */}
              {error && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white p-6 rounded-lg max-w-md">
                    <div className="flex items-center mb-4">
                      <AlertCircle className="text-red-500 mr-2" />
                      <h3 className="text-lg font-semibold">{t.error}</h3>
                    </div>
                    <p className="text-gray-600 mb-4">{error}</p>
                    <button
                      onClick={() => setError(null)}
                      className="w-full py-2 px-4 bg-red-600 text-white rounded-lg hover:bg-red-700"
                    >
                      {t.close}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        } />
        <Route path="/profile" element={<Profile />} />
        <Route path="/cancel-ticket" element={<CancelTicket />} />
      </Routes>
    </Router>
  );
};

export default App;