import React, { useState } from 'react';
import Modal from './Modal';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import { getStationName } from '../utils/stations';
import { MapPin, ArrowRight } from 'lucide-react';

// Регистрируем русскую локаль
registerLocale('ru', ru);

// Добавляем объект с текстом правил
const termsData = {
  ru: {
    title: 'Условия и правила покупки и оплаты билетов RegioJet',
    content: `1. Общие положения
1.1. Настоящие условия регулируют порядок приобретения и оплаты билетов на транспортные услуги компании RegioJet.
1.2. Покупая билет, пассажир соглашается с данными условиями и обязуется соблюдать их.
1.3. Данные условия могут изменяться без предварительного уведомления.

2. Покупка билетов
2.1. Билеты можно приобрести онлайн через официальный сайт RegioJet, мобильное приложение, в кассах компании или у авторизованных партнеров.
2.2. Для покупки билета необходимо указать корректные персональные данные, включая имя, фамилию и контактную информацию.
2.3. После успешной оплаты билет будет отправлен на указанный адрес электронной почты или будет доступен в личном кабинете на сайте.

3. Оплата билетов
3.1. Оплата может быть произведена следующими способами:
- Банковской картой (Visa, MasterCard, American Express)
- Электронными платежными системами (Apple Pay, Google Pay, PayPal)
- Через банковский перевод (при заказе через корпоративный отдел)
- В кассах RegioJet наличными или картой

3.2. Все транзакции защищены и обрабатываются с использованием современных методов шифрования.
3.3. Валюта платежа зависит от страны покупки билета.

4. Изменение и возврат билетов
4.1. Изменение даты и времени поездки возможно в зависимости от тарифа билета.
4.2. Возврат билета возможен в соответствии с условиями тарифа и в установленные сроки.
4.3. В некоторых тарифах билеты являются невозвратными.
4.4. Для запроса возврата необходимо обратиться в службу поддержки RegioJet.

5. Ответственность пассажира
5.1. Пассажир обязан прибыть на посадку заранее.
5.2. В случае опоздания на рейс ответственность несет пассажир.
5.3. Пассажир обязан соблюдать правила поведения на борту.

6. Дополнительные условия
6.1. RegioJet оставляет за собой право отменять или изменять рейсы.
6.2. В случае отмены рейса пассажиру будет предложен альтернативный вариант поездки или возврат средств.
6.3. Вопросы, не урегулированные данными условиями, регулируются действующим законодательством.`
  },
  en: {
    title: 'Terms and Conditions for RegioJet Ticket Purchase and Payment',
    content: `1. General Provisions
1.1. These terms govern the procedure for purchasing and paying for RegioJet transport services tickets.
1.2. By purchasing a ticket, the passenger agrees to these terms and undertakes to comply with them.
1.3. These terms may change without prior notice.

2. Ticket Purchase
2.1. Tickets can be purchased online through the official RegioJet website, mobile app, at company ticket offices, or through authorized partners.
2.2. To purchase a ticket, you must provide correct personal information, including name, surname, and contact details.
2.3. After successful payment, the ticket will be sent to the specified email address or will be available in your personal account on the website.

3. Ticket Payment
3.1. Payment can be made by the following methods:
- Bank cards (Visa, MasterCard, American Express)
- Electronic payment systems (Apple Pay, Google Pay, PayPal)
- Bank transfer (for corporate bookings)
- Cash or card at RegioJet ticket offices

3.2. All transactions are secure and processed using modern encryption methods.
3.3. Payment currency depends on the country of ticket purchase.

4. Ticket Changes and Refunds
4.1. Changes to travel date and time are possible depending on the ticket fare.
4.2. Ticket refunds are possible according to fare conditions and within established timeframes.
4.3. Some fare types are non-refundable.
4.4. For refund requests, contact RegioJet customer support.

5. Passenger Responsibility
5.1. Passengers must arrive for boarding in advance.
5.2. Passengers are responsible for missing their departure.
5.3. Passengers must comply with onboard conduct rules.

6. Additional Conditions
6.1. RegioJet reserves the right to cancel or modify services.
6.2. In case of service cancellation, passengers will be offered alternative travel options or refunds.
6.3. Issues not covered by these terms are governed by applicable law.`
  }
};

// Форматирование даты для отображения
const formatDateForInput = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

// Форматирование даты для отправки на сервер
const formatDateForServer = (date) => {
  if (!(date instanceof Date)) {
    return new Date(date).toISOString().split('T')[0];
  }
  return date.toISOString().split('T')[0];
};

const BookingForm = ({ onBook, route, seats, passengers, t }) => {
  // В начале компонента добавим состояние для маски телефона
  const [phonePrefix] = useState('+420'); // Чешский префикс для отображения

  // Изменяем структуру состояния для поддержки нескольких пассажиров
  const [passengerForms, setPassengerForms] = useState(
    Array(passengers).fill().map(() => ({
      firstName: '',
      lastName: '',
      email: '',
      phone: '+',
      dateOfBirth: new Date(new Date().setFullYear(new Date().getFullYear() - 20)) // По умолчанию 20 лет назад
    }))
  );

  const [sendIndividualEmails, setSendIndividualEmails] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  // Добавляем текст для чекбокса на двух языках
  const checkboxText = {
    ru: {
      accept: 'Я принимаю',
      terms: 'условия и правила',
      ticketPurchase: 'покупки и оплаты билетов'
    },
    en: {
      accept: 'I accept the',
      terms: 'terms and conditions',
      ticketPurchase: 'for ticket purchase and payment'
    }
  };

  // Определяем текущий язык (предполагаем, что он передается в пропсах t)
  const currentLang = t.locale || 'ru'; // Используем 'ru' как язык по умолчанию

  // Функция валидации телефона
  const validatePhone = (phone) => {
    // Проверяем, что номер начинается с + и содержит от 10 до 12 цифр после
    const phoneRegex = /^\+\d{10,12}$/;
    
    if (!phoneRegex.test(phone)) {
      return false;
    }
    
    return phone;
  };

  // Добавляем нового пассажира
  const handleAddPassenger = () => {
    if (passengerForms.length < passengers) {
      setPassengerForms([
        ...passengerForms,
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '+', // Теперь начинаем только с +
          dateOfBirth: new Date().toISOString().split('T')[0]
        }
      ]);
    }
  };

  // Удаляем пассажира
  const handleRemovePassenger = (index) => {
    if (passengerForms.length > 1) {
      setPassengerForms(passengerForms.filter((_, i) => i !== index));
    }
  };

  // Обновляем данные пассажира
  const handlePassengerChange = (index, field, value) => {
    const newForms = [...passengerForms];
    newForms[index] = {
      ...newForms[index],
      [field]: value
    };
    setPassengerForms(newForms);
  };

  // Валидация формы
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    passengerForms.forEach((passenger, index) => {
      const passengerErrors = {};

      if (!passenger.firstName.trim()) {
        passengerErrors.firstName = t.firstNameRequired;
        isValid = false;
      }

      if (!passenger.lastName.trim()) {
        passengerErrors.lastName = t.lastNameRequired;
        isValid = false;
      }

      if (!passenger.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(passenger.email)) {
        passengerErrors.email = t.invalidEmail;
        isValid = false;
      }

      if (!passenger.phone.trim()) {
        passengerErrors.phone = t.phoneRequired;
        isValid = false;
      }

      if (!passenger.dateOfBirth) {
        passengerErrors.dateOfBirth = t.dateOfBirthRequired;
        isValid = false;
      }

      if (Object.keys(passengerErrors).length > 0) {
        newErrors[index] = passengerErrors;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      // Валидируем телефоны перед отправкой
      const validatedPassengers = passengerForms.map(passenger => {
        const validPhone = validatePhone(passenger.phone);
        if (!validPhone) {
          throw new Error(t.invalidPhone);
        }
        return {
          ...passenger,
          lastName: passenger.lastName,
          dateOfBirth: new Date(passenger.dateOfBirth)
        };
      });

      await onBook({
        passengers: validatedPassengers
      });
    } catch (err) {
      console.error('Form submission error:', err);
      setError(err.message || t.bookingError);
    } finally {
      setLoading(false);
    }
  };

  // Добавим расчет общей стоимости
  const calculateTotalPrice = () => {
    const pricePerTicket = parseFloat(route.price);
    const totalPrice = pricePerTicket * passengers;
    return {
      amount: totalPrice,
      formatted: `${totalPrice.toFixed(2)} ${route.currency}`
    };
  };

  // Кастомный компонент для поля даты
  const CustomDateInput = React.forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      type="text"
      value={value}
      onChange={onChange}
      onClick={onClick}
      ref={ref}
      placeholder="ДД.ММ.ГГГГ"
      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    />
  ));

  // Форматирование времени
  const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString('ru-RU', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Форматирование даты
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  return (
    <div className="max-w-2xl mx-auto">
       <form onSubmit={handleSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow-md">
        {/* Заголовок */}
        <h2 className="text-2xl font-bold text-gray-900 mb-6">{t.passengerDetails}</h2>
        
        {/* Информация о маршруте и месте */}
        <div className="bg-blue-50 p-4 rounded-lg mb-6">
          <h3 className="font-medium text-blue-900 mb-2">{t.bookingSummary}</h3>
          <div className="space-y-2">
            <div className="text-sm text-gray-600">
              <p className="font-medium text-gray-700 mb-1">{t.route}:</p>
              <div className="flex items-center space-x-2">
                <div className="flex items-center">
                  <MapPin size={16} className="text-gray-400 mr-1" />
                  <span>{getStationName(route.departureStationId, currentLang)}</span>
                </div>
                <ArrowRight size={16} className="text-gray-400" />
                <div className="flex items-center">
                  <MapPin size={16} className="text-gray-400 mr-1" />
                  <span>{getStationName(route.arrivalStationId, currentLang)}</span>
                </div>
              </div>
              <p className="mt-1">
                {formatDate(route.departureTime)}, {formatTime(route.departureTime)}
              </p>
            </div>
            <div className="text-sm text-gray-600">
              <p className="font-medium text-gray-700 mb-1">{t.seat}:</p>
              {seats.map((seat, index) => (
                <p key={index}>
                  {t.vehicle} {seat.vehicleNumber}, {t.seat} {seat.number}
                </p>
              ))}
            </div>
            {/* Блок с ценой */}
            <div className="mt-4 pt-4 border-t border-gray-200">
              <div className="flex justify-between items-center">
                <p className="text-lg font-bold text-gray-900">
                  {route.price} {route.currency}
                </p>
              </div>
            </div>
          </div>
        </div>

        {passengerForms.map((passenger, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">
                {t.passenger} {index + 1}
              </h3>
              {passengerForms.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleRemovePassenger(index)}
                  className="text-red-600 hover:text-red-800"
                >
                  {t.remove}
                </button>
              )}
            </div>

            {/* Поля формы для каждого пассажира */}
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    {t.firstName} *
                  </label>
                  <input
                    type="text"
                    value={passenger.firstName}
                    onChange={(e) => handlePassengerChange(index, 'firstName', e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                  {errors[index]?.firstName && (
                    <p className="mt-1 text-sm text-red-600">{errors[index].firstName}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    {t.lastName} *
                  </label>
                  <input
                    type="text"
                    value={passenger.lastName}
                    onChange={(e) => handlePassengerChange(index, 'lastName', e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                  {errors[index]?.lastName && (
                    <p className="mt-1 text-sm text-red-600">{errors[index].lastName}</p>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {t.email} *
                </label>
                <input
                  type="email"
                  value={passenger.email}
                  onChange={(e) => handlePassengerChange(index, 'email', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
                {errors[index]?.email && (
                  <p className="mt-1 text-sm text-red-600">{errors[index].email}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {t.phone}
                </label>
                <input
                  type="tel"
                  value={passenger.phone}
                  onChange={(e) => handlePassengerChange(index, 'phone', e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="+XXX XXXXXXXXX"
                  required
                />
                <p className="mt-1 text-sm text-gray-500">
                  {t.phoneHelp || 'Введите номер телефона в любом формате'}
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t.dateOfBirth} *
                </label>
                <DatePicker
                  selected={passenger.dateOfBirth}
                  onChange={(date) => handlePassengerChange(index, 'dateOfBirth', date)}
                  dateFormat="dd.MM.yyyy"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  locale="ru"
                  customInput={<CustomDateInput />}
                  maxDate={new Date()}
                  calendarClassName="border border-gray-200 rounded-lg shadow-lg"
                  wrapperClassName="w-full"
                  popperClassName="z-50"
                  popperPlacement="bottom-start"
                />
              </div>
            </div>
          </div>
        ))}

        {/* Кнопка добавления пассажира */}
        {passengerForms.length < passengers && (
          <button
            type="button"
            onClick={handleAddPassenger}
            className="w-full py-2 px-4 border border-blue-500 text-blue-500 rounded-md hover:bg-blue-50"
          >
            {t.addPassenger}
          </button>
        )}

        {/* Опция отправки индивидуальных писем */}
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="sendEmails"
            checked={sendIndividualEmails}
            onChange={(e) => setSendIndividualEmails(e.target.checked)}
            className="rounded border-gray-300"
          />
          <label htmlFor="sendEmails" className="text-sm text-gray-700">
            {t.sendIndividualEmails}
          </label>
        </div>

        {/* Чекбокс с условиями - обновляем текст */}
        <div className="mb-4">
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                type="checkbox"
                id="terms"
                checked={isTermsAccepted}
                onChange={(e) => setIsTermsAccepted(e.target.checked)}
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                required
              />
            </div>
            <label htmlFor="terms" className="ml-2 text-sm text-gray-700">
              {checkboxText[currentLang].accept}{' '}
              <button
                type="button"
                onClick={() => setShowTermsModal(true)}
                className="text-blue-600 underline hover:text-blue-800"
              >
                {checkboxText[currentLang].terms}
              </button>{' '}
              {checkboxText[currentLang].ticketPurchase}
            </label>
          </div>
        </div>

        {/* Общая ошибка */}
        {error && (
          <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
            {error}
          </div>
        )}

        {/* Кнопка отправки */}
        <button
          type="submit"
          disabled={!isTermsAccepted || loading}
          className={`
            w-full py-3 px-4 rounded-md text-white font-medium
            ${loading || !isTermsAccepted
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-[#9a55f4] hover:bg-[#8645e0]'
            }
            transition-colors duration-200
          `}
        >
          {loading ? t.processing : (t.payNow || 'Оплатить')}
        </button>

        {/* Примечание об обязательных полях */}
        <p className="text-sm text-gray-500 text-center">
          * {t.requiredFields}
        </p>

        {/* Модальное окно с условиями */}
        {showTermsModal && (
          <Modal onClose={() => setShowTermsModal(false)}>
            <div className="max-w-4xl mx-auto">
              <div className="flex justify-end mb-4">
                <button
                  type="button"
                  onClick={() => setShowTermsModal(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              <div className="space-y-4">
                <h2 className="text-2xl font-bold mb-4">{termsData.ru.title}</h2>
                <div className="whitespace-pre-wrap">{termsData.ru.content}</div>
                <hr className="my-8" />
                <h2 className="text-2xl font-bold mb-4">{termsData.en.title}</h2>
                <div className="whitespace-pre-wrap">{termsData.en.content}</div>
              </div>
            </div>
          </Modal>
        )}
      </form>
    </div>
  );
};

export default BookingForm;