import React, { useState, useEffect } from 'react';
import { Search, Calendar } from 'lucide-react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import api from '../services/regiojet-api';
import LoadingScreen from './LoadingScreen';
import PassengerSelect from './PassengerSelect';
import { getAllStations, findStationsByName } from '../utils/stations';

// Регистрируем русскую локаль
registerLocale('ru', ru);

// Форматирование даты для отображения
const formatDateForInput = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

// Форматирование даты для отправки на сервер
const formatDateForServer = (date) => {
  if (!(date instanceof Date)) {
    return new Date(date).toISOString().split('T')[0];
  }
  return date.toISOString().split('T')[0];
};

const SearchForm = ({ onSearch, t, passengers, onPassengersChange }) => {
  // Состояния
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [stations, setStations] = useState([]);
  
  // Форма
  const [formData, setFormData] = useState({
    fromLocationId: '',
    toLocationId: '',
    departureDate: formatDateForInput(new Date()),
    tariffs: ['REGULAR']
  });

  // Загрузка локаций при монтировании
  useEffect(() => {
    const loadLocations = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await api.getLocations();
        
        // Сортируем локации по имени
        const sortedLocations = response.sort((a, b) => 
          a.name.localeCompare(b.name)
        );
        
        setLocations(sortedLocations);
      } catch (err) {
        console.error('Error loading locations:', err);
        setError(t.errorLoadingLocations || 'Error loading locations');
      } finally {
        setLoading(false);
      }
    };

    loadLocations();
  }, [t]);

  useEffect(() => {
    // Загружаем и сортируем все станции при монтировании компонента
    const sortedStations = getAllStations('ru').sort((a, b) => 
      a.name.localeCompare(b.name, 'ru', { sensitivity: 'base' })
    );
    setStations(sortedStations);
  }, []);

  // Обработчик изменения формы
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Для даты применяем специальную обработку
    if (name === 'departureDate') {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  // Обработчик изменения даты
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData(prev => ({
      ...prev,
      departureDate: formatDateForInput(date)
    }));
  };

  // Обработчик отправки формы
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      if (!formData.fromLocationId || !formData.toLocationId || !selectedDate) {
        throw new Error(t.pleaseSelectAllFields || 'Please select all required fields');
      }

      await onSearch({
        fromLocationId: Number(formData.fromLocationId),
        toLocationId: Number(formData.toLocationId),
        departureDate: formatDateForServer(selectedDate),
        passengers: Number(passengers),
        tariffs: formData.tariffs
      });
    } catch (err) {
      console.error('Search form error:', err);
      setError(err.message);
    }
  };

  // Получение минимальной даты
  const getMinDate = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  // Кастомный компонент для иконки календаря
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="relative">
      <input
        type="text"
        value={value}
        onClick={onClick}
        ref={ref}
        readOnly
        className="w-full pl-14 sm:pl-12 pr-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none cursor-pointer"
      />
      <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none z-10" size={20} />
    </div>
  ));

  return (
    <div className="w-full max-w-2xl mx-auto relative">
      {loading && (
        <LoadingScreen t={{
          loading: t.loadingLocations || 'Загрузка...',
          pleaseWait: t.pleaseWait || 'Пожалуйста, подождите'
        }} />
      )}
      
      <div className="relative h-64 mb-8 rounded-lg overflow-hidden">
        <img
          src="/travel-background.png"
          alt="Travel"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-center items-center text-white p-6">
          <h1 className="text-4xl font-bold mb-2">
            {t.bannerTitle || 'Путешествуйте с нами'}
          </h1>
          <p className="text-xl">
            {t.bannerSubtitle || 'Бронируйте билеты на поезд, автобус, самолет и паром'}
          </p>
        </div>
      </div>
      
      <form onSubmit={handleSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow-md">
        {/* Заголовок */}
        <h2 className="text-2xl font-bold text-gray-800 mb-6">
          {t.searchRoutes || 'Поиск маршрутов'}
        </h2>

        {/* Поле "Откуда" */}
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none z-10" size={20} />
          <select
            name="fromLocationId"
            value={formData.fromLocationId}
            onChange={handleInputChange}
            className="w-full pl-14 sm:pl-12 pr-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white appearance-none"
            required
          >
            <option value="">{t.from || 'Откуда'}</option>
            {stations.map(station => (
              <option key={station.id} value={station.id}>
                {station.name}
              </option>
            ))}
          </select>
        </div>

        {/* Поле "Куда" */}
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none z-10" size={20} />
          <select
            name="toLocationId"
            value={formData.toLocationId}
            onChange={handleInputChange}
            className="w-full pl-14 sm:pl-12 pr-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white appearance-none"
            required
          >
            <option value="">{t.to || 'Куда'}</option>
            {stations.map(station => (
              <option key={station.id} value={station.id}>
                {station.name}
              </option>
            ))}
          </select>
        </div>

        {/* Дата отправления */}
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd.MM.yyyy"
          minDate={getMinDate()}
          locale="ru"
          customInput={<CustomInput />}
          calendarClassName="border border-gray-200 rounded-lg shadow-lg"
          wrapperClassName="w-full"
          popperClassName="z-50"
          popperPlacement="bottom-start"
          placeholderText="ДД.ММ.ГГГГ"
        />

        {/* Добавляем выбор пассажиров */}
        <div className="flex items-center space-x-4">
          <PassengerSelect 
            value={passengers}
            onChange={onPassengersChange}
            t={t}
          />
        </div>

        {/* Отображение ошибки */}
        {error && (
          <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
            {error}
          </div>
        )}

        {/* Кнопка поиска */}
        <button
          type="submit"
          className="w-full bg-[#9a55f4] hover:bg-[#8645e0] text-white py-3 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center space-x-2"
          disabled={loading}
        >
          <Search size={20} />
          <span>{t.search || 'Search Routes'}</span>
        </button>
      </form>
    </div>
  );
};

export default SearchForm;