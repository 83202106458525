export const STATION_NAMES = {
  // Основные станции
  '10202052': {
    ru: 'Вена',
    en: 'Vienna'
  },
  '888040003': {
    ru: 'Зальцбург',
    en: 'Salzburg'
  },
  '888040004': {
    ru: 'Инсбрук',
    en: 'Innsbruck'
  },
  '10202089': {
    ru: 'Дьёр',
    en: 'Györ'
  },
  '17904003': {
    ru: 'Либерец',
    en: 'Liberec'
  },
  '10202035': {
    ru: 'Попрад',
    en: 'Poprad'
  },
  '10202002': {
    ru: 'Брно',
    en: 'Brno'
  },
  '10202003': {
    ru: 'Прага',
    en: 'Prague'
  },
  '10202001': {
    ru: 'Братислава',
    en: 'Bratislava'
  },
  '10202004': {
    ru: 'Пльзень',
    en: 'Pilsen'
  },
  '10202000': {
    ru: 'Острава',
    en: 'Ostrava'
  },
  '10202031': {
    ru: 'Оломоуц',
    en: 'Olomouc'
  },
  '3088864007': {
    ru: 'Ческе-Будеёвице',
    en: 'České Budějovice'
  },
  '3088864008': {
    ru: 'Пардубице',
    en: 'Pardubice'
  },
  '3088864009': {
    ru: 'Градец-Кралове',
    en: 'Hradec Králové'
  },
  '3088864010': {
    ru: 'Усти-над-Лабем',
    en: 'Ústí nad Labem'
  },
  '3088865002': {
    ru: 'Кошице',
    en: 'Košice'
  },
  '3088865004': {
    ru: 'Банска-Бистрица',
    en: 'Banská Bystrica'
  },
  '3088865005': {
    ru: 'Прешов',
    en: 'Prešov'
  },
  '3088865006': {
    ru: 'Нитра',
    en: 'Nitra'
  },
  '3088865007': {
    ru: 'Тренчин',
    en: 'Trenčín'
  },
  '3088865008': {
    ru: 'Мартин',
    en: 'Martin'
  },
  '3088866002': {
    ru: 'Грац',
    en: 'Graz'
  },
  '3088866003': {
    ru: 'Линц',
    en: 'Linz'
  },
  '3088866006': {
    ru: 'Клагенфурт',
    en: 'Klagenfurt'
  },
  '3088867003': {
    ru: 'Гамбург',
    en: 'Hamburg'
  },
  '3088867005': {
    ru: 'Лейпциг',
    en: 'Leipzig'
  },
  '3088867006': {
    ru: 'Нюрнберг',
    en: 'Nuremberg'
  },
  '3088868001': {
    ru: 'Варшава',
    en: 'Warsaw'
  },
  '3088868003': {
    ru: 'Вроцлав',
    en: 'Wrocław'
  },
  '3088868004': {
    ru: 'Познань',
    en: 'Poznań'
  },
  '3088868005': {
    ru: 'Гданьск',
    en: 'Gdańsk'
  },
  '3088868006': {
    ru: 'Катовице',
    en: 'Katowice'
  },
  '3088869002': {
    ru: 'Дебрецен',
    en: 'Debrecen'
  },
  '3088869003': {
    ru: 'Сегед',
    en: 'Szeged'
  },
  '3088869004': {
    ru: 'Мишкольц',
    en: 'Miskolc'
  },
  '3088869005': {
    ru: 'Печ',
    en: 'Pécs'
  },
  '10202072': {
    ru: 'Берлин',
    en: 'Berlin'
  },
  '10202020': {
    ru: 'Берн',
    en: 'Bern'
  },
  '10202084': {
    ru: 'Болонья',
    en: 'Bologna'
  },
  '10202044': {
    ru: 'Брюссель',
    en: 'Brussels'
  },
  '10202091': {
    ru: 'Будапешт',
    en: 'Budapest'
  },
  '10202071': {
    ru: 'Дрезден',
    en: 'Dresden'
  },
  '10202104': {
    ru: 'Дюссельдорф',
    en: 'Düsseldorf'
  },
  '10202026': {
    ru: 'Эйндховен',
    en: 'Eindhoven'
  },
  '10202085': {
    ru: 'Флоренция',
    en: 'Florence'
  },
  '10202023': {
    ru: 'Франкфурт-на-Майне',
    en: 'Frankfurt am Main'
  },
  '10202069': {
    ru: 'Женева',
    en: 'Geneve'
  },
  '10202049': {
    ru: 'Лондон',
    en: 'London'
  },
  '10202082': {
    ru: 'Милан',
    en: 'Milan'
  },
  '10202006': {
    ru: 'Мюнхен',
    en: 'Munich'
  },
  '10202021': {
    ru: 'Нюрнберг',
    en: 'Nuremberg'
  },
  '10202079': {
    ru: 'Падуя',
    en: 'Padua'
  },
  '10202096': {
    ru: 'Париж',
    en: 'Paris'
  },
  '10202086': {
    ru: 'Рим',
    en: 'Rome'
  },
  '10202028': {
    ru: 'Роттердам',
    en: 'Rotterdam'
  },
  '10202066': {
    ru: 'Санкт-Галлен',
    en: 'St.Gallen'
  },
  '10202009': {
    ru: 'Штутгарт',
    en: 'Stuttgart'
  },
  '10202078': {
    ru: 'Удине',
    en: 'Udine'
  },
  '10202080': {
    ru: 'Венеция',
    en: 'Venice'
  },
  '10202067': {
    ru: 'Цюрих',
    en: 'Zürich'
  },
  '3741270006': {
    ru: 'Бановце-над-Ондавой',
    en: 'Bánovce nad Ondavou'
  },
  '10202062': {
    ru: 'Банска-Бистрица',
    en: 'Banská Bystrica'
  },
  '14780002': {
    ru: 'Бардеёв',
    en: 'Bardejov'
  },
  '7133810001': {
    ru: 'Батёво',
    en: 'Batiovo'
  },
  '6632040017': {
    ru: 'Бердичев',
    en: 'Berdičev'
  },
  '6632040016': {
    ru: 'Белая Церковь',
    en: 'Bila Cerkva'
  },
  '3334999001': {
    ru: 'Биоград-на-Мору',
    en: 'Biograd na Moru'
  },
  '3741270003': {
    ru: 'Бланско',
    en: 'Blansko'
  },
  '2147875000': {
    ru: 'Богумин',
    en: 'Bohumín'
  },
  '7133810010': {
    ru: 'Болехов',
    en: 'Bolekhiv'
  },
  '165611000': {
    ru: 'Бржецлав',
    en: 'Břeclav'
  },
  '1313136001': {
    ru: 'Быстрице',
    en: 'Bystřice (Třinec)'
  },
  '508808002': {
    ru: 'Чадца',
    en: 'Čadca'
  },
  '7133810002': {
    ru: 'Карпаты',
    en: 'Carpathians'
  },
  '6527887004': {
    ru: 'Целе',
    en: 'Celje'
  },
  '1313136000': {
    ru: 'Ческа-Тршебова',
    en: 'Česká Třebová'
  },
  '17904008': {
    ru: 'Ческе-Будеёвице',
    en: 'České Budějovice'
  },
  '17904009': {
    ru: 'Чески-Крумлов',
    en: 'Český Krumlov'
  },
  '508808000': {
    ru: 'Чески-Тешин',
    en: 'Český Těšín'
  },
  '6632040001': {
    ru: 'Харьков',
    en: 'Charkov'
  },
  '721174001': {
    ru: 'Хеб',
    en: 'Cheb'
  },
  '7133810016': {
    ru: 'Черновцы',
    en: 'Chernivtsi'
  },
  '6133164002': {
    ru: 'Хмельницкий',
    en: 'Chmelnyckyj'
  },
  '1084369003': {
    ru: 'Хомутов',
    en: 'Chomutov'
  },
  '7122881001': {
    ru: 'Чоп',
    en: 'Chop'
  },
  '241620000': {
    ru: 'Аэропорт Кёльн/Бонн',
    en: 'Cologne/Bonn Airport'
  },
  '2930911000': {
    ru: 'Комо',
    en: 'Como'
  },
  '1225791000': {
    ru: 'Краков',
    en: 'Cracow'
  },
  '7537091001': {
    ru: 'Чеховице-Дзедзице',
    en: 'Czechowice-Dziedzice'
  },
  '6632040012': {
    ru: 'Днепр',
    en: 'Dnipro'
  },
  '6633198001': {
    ru: 'Днепродзержинск',
    en: 'Dniprodzeržynsk'
  },
  '7133810011': {
    ru: 'Долина',
    en: 'Dolyna'
  },
  '6632040005': {
    ru: 'Фастов',
    en: 'Fastiv'
  },
  '3001552002': {
    ru: 'Гиралтовце',
    en: 'Giraltovce'
  },
  '372842004': {
    ru: 'Гавиржов',
    en: 'Havířov'
  },
  '5845172001': {
    ru: 'Гавличкув-Брод',
    en: 'Havlíčkův Brod'
  },
  '3798068000': {
    ru: 'Хедьешхалом',
    en: 'Hegyeshalom'
  },
  '372842003': {
    ru: 'Границе-на-Мораве',
    en: 'Hranice na M.'
  },
  '6693502002': {
    ru: 'Гребёнка',
    en: 'Hrebinka'
  },
  '2370326000': {
    ru: 'Гулин',
    en: 'Hulín'
  },
  '14780004': {
    ru: 'Гуменне',
    en: 'Humenné'
  },
  '7133810014': {
    ru: 'Ивано-Франковск',
    en: 'Ivano-Frankivsk'
  },
  '17554000': {
    ru: 'Йиглава',
    en: 'Jihlava'
  },
  '1303398000': {
    ru: 'Йирков',
    en: 'Jirkov'
  },
  '5095523072': {
    ru: 'Йистебник',
    en: 'Jistebník'
  },
  '7133810013': {
    ru: 'Калуш',
    en: 'Kalush'
  },
  '6632040007': {
    ru: 'Калиновка',
    en: 'Kalynivka'
  },
  '6632040013': {
    ru: 'Каменское',
    en: 'Kamianske'
  },
  '1225791001': {
    ru: 'Катовице',
    en: 'Katowice'
  },
  '14780000': {
    ru: 'Кежмарок',
    en: 'Kežmarok'
  },
  '4961571001': {
    ru: 'Коетин',
    en: 'Kojetín'
  },
  '3088840000': {
    ru: 'Колин',
    en: 'Kolín'
  },
  '7133810015': {
    ru: 'Коломыя',
    en: 'Kolomyia'
  },
  '10202033': {
    ru: 'Кошице',
    en: 'Košice'
  },
  '6632040006': {
    ru: 'Казатин',
    en: 'Kozjatyn'
  },
  '6632040010': {
    ru: 'Красное',
    en: 'Krasne'
  },
  '17904000': {
    ru: 'Кромержиж',
    en: 'Kroměříž'
  },
  '271526028': {
    ru: 'Киев',
    en: 'Kyiv'
  },
  '1762994001': {
    ru: 'Кисак',
    en: 'Kysak'
  },
  '10202068': {
    ru: 'Лозанна',
    en: 'Lausanne'
  },
  '3741270002': {
    ru: 'Летовице',
    en: 'Letovice'
  },
  '7070607001': {
    ru: 'Липник-над-Бечвой',
    en: 'Lipník nad Bečvou'
  },
  '10202036': {
    ru: 'Липтовски-Микулаш',
    en: 'Liptovský Mikuláš'
  },
  '5010254221': {
    ru: 'Литомержице',
    en: 'Litoměřice'
  },
  '1084369001': {
    ru: 'Лоуни',
    en: 'Louny'
  },
  '6632040004': {
    ru: 'Лубны',
    en: 'Lubny'
  },
  '4552142000': {
    ru: 'Люцерн',
    en: 'Lucerne'
  },
  '4552142001': {
    ru: 'Лугано',
    en: 'Lugano'
  },
  '10202045': {
    ru: 'Люксембург',
    en: 'Luxembourg'
  },
  '271526030': {
    ru: 'Львов',
    en: 'Lviv'
  },
  '2651667000': {
    ru: 'Лион',
    en: 'Lyon'
  },
  '5812839004': {
    ru: 'Лиса-над-Лабем',
    en: 'Lysá nad Labem'
  },
  '6693502001': {
    ru: 'Люботин',
    en: 'Lyubotin'
  },
  '2317706000': {
    ru: 'Маргецаны',
    en: 'Margecany'
  },
  '49584001': {
    ru: 'Мартин',
    en: 'Martin'
  },
  '5010254426': {
    ru: 'Мельник',
    en: 'Mělník'
  },
  '14780003': {
    ru: 'Михаловце',
    en: 'Michalovce'
  },
  '2624922002': {
    ru: 'Моравске-Будеёвице',
    en: 'Moravské Budějovice'
  },
  '7133810009': {
    ru: 'Моршин',
    en: 'Morshyn'
  },
  '10202088': {
    ru: 'Мошонмадьяровар',
    en: 'Mosonmagyaróvár'
  },
  '1084369000': {
    ru: 'Мост',
    en: 'Most'
  },
  '4016707000': {
    ru: 'Мукачево',
    en: 'Mukachevo'
  },
  '6632040003': {
    ru: 'Миргород',
    en: 'Myrhorod'
  },
  '1558067000': {
    ru: 'Навси',
    en: 'Návsí'
  },
  '5009205000': {
    ru: 'Незамыслице',
    en: 'Nezamyslice'
  },
  '5118475002': {
    ru: 'Нове-Место-над-Вагом',
    en: 'Nové Mesto nad Váhom'
  },
  '10202032': {
    ru: 'Новы-Йичин',
    en: 'Nový Jičín'
  },
  '5812839003': {
    ru: 'Нимбурк',
    en: 'Nymburk'
  },
  '6693502003': {
    ru: 'Александрия',
    en: 'Oleksandriia'
  },
  '3741270000': {
    ru: 'Опава-восток',
    en: 'Opava východ'
  },
  '3334999002': {
    ru: 'Пакоштане',
    en: 'Pakoštane'
  },
  '2624922003': {
    ru: 'Павлице',
    en: 'Pavlice'
  },
  '5118475001': {
    ru: 'Пьештяны',
    en: 'Piešťany'
  },
  '17904007': {
    ru: 'Писек',
    en: 'Písek'
  },
  '5812839002': {
    ru: 'Подебрады',
    en: 'Poděbrady'
  },
  '49584004': {
    ru: 'Подолинец',
    en: 'Podolínec'
  },
  '6632040002': {
    ru: 'Полтава',
    en: 'Poltava'
  },
  '10202039': {
    ru: 'Поважска-Бистрица',
    en: 'Považská Bystrica'
  },
  '5990055004': {
    ru: 'Пшемысль',
    en: 'Přemyšl'
  },
  '423394000': {
    ru: 'Пршибор',
    en: 'Příbor'
  },
  '271526009': {
    ru: 'Примоштен',
    en: 'Primošten'
  },
  '5118475004': {
    ru: 'Пухов',
    en: 'Púchov'
  },
  '3001552000': {
    ru: 'Раславице',
    en: 'Raslavice'
  },
  '5990055007': {
    ru: 'Жешув',
    en: 'Řešov'
  },
  '10202037': {
    ru: 'Ружомберок',
    en: 'Ružomberok'
  },
  '1606573000': {
    ru: 'Рыбник',
    en: 'Rybnik'
  },
  '49819006': {
    ru: 'Сечовце',
    en: 'Sečovce'
  },
  '271526012': {
    ru: 'Шибеник',
    en: 'Šibenik'
  },
  '4406925000': {
    ru: 'Скалице-над-Свитавой',
    en: 'Skalice nad Svitavou'
  },
  '7133810006': {
    ru: 'Сколе',
    en: 'Skole'
  },
  '7133810005': {
    ru: 'Славско',
    en: 'Slavsko'
  },
  '6632040015': {
    ru: 'Смела',
    en: 'Smila'
  },
  '14780005': {
    ru: 'Снина',
    en: 'Snina'
  },
  '721174000': {
    ru: 'Соколов',
    en: 'Sokolov'
  },
  '49584003': {
    ru: 'Спишска-Бела',
    en: 'Spišská Belá'
  },
  '1762994000': {
    ru: 'Спишска-Нова-Вес',
    en: 'Spišská Nová Ves'
  },
  '271526010': {
    ru: 'Сплит',
    en: 'Split'
  },
  '4111558000': {
    ru: 'Санкт-Пёльтен',
    en: 'St. Pölten'
  },
  '5812839005': {
    ru: 'Стара-Болеслав',
    en: 'Stará Boleslav'
  },
  '14780001': {
    ru: 'Стара-Любовня',
    en: 'Stará Ľubovňa'
  },
  '5010254372': {
    ru: 'Штети',
    en: 'Štětí'
  },
  '1805420001': {
    ru: 'Страсбург',
    en: 'Strasbourg'
  },
  '3741270007': {
    ru: 'Стражске',
    en: 'Strážske'
  },
  '49584002': {
    ru: 'Штрба',
    en: 'Štrba'
  },
  '3001552004': {
    ru: 'Стропков',
    en: 'Stropkov'
  },
  '7133810008': {
    ru: 'Стрый',
    en: 'Stryi'
  },
  '4961571003': {
    ru: 'Студенка',
    en: 'Studénka'
  },
  '5095523141': {
    ru: 'Сухдол-над-Одрой',
    en: 'Suchdol n.Odrou'
  },
  '3334999000': {
    ru: 'Сукошан',
    en: 'Sukošan'
  },
  '7133810003': {
    ru: 'Свалява',
    en: 'Svaliava'
  },
  '3001552003': {
    ru: 'Свидник',
    en: 'Svidník'
  },
  '3741270001': {
    ru: 'Свитавы',
    en: 'Svitavy'
  },
  '6256272001': {
    ru: 'Тарнов',
    en: 'Tarnov'
  },
  '271526038': {
    ru: 'Тернополь',
    en: 'Ternopil'
  },
  '4971491000': {
    ru: 'Гаага',
    en: 'The Hague'
  },
  '5118475003': {
    ru: 'Тренчьянска-Тепла',
    en: 'Trenčianska Teplá'
  },
  '508808001': {
    ru: 'Тршинец',
    en: 'Třinec centrum'
  },
  '1723745000': {
    ru: 'Трнава',
    en: 'Trnava'
  },
  '271526013': {
    ru: 'Трогир',
    en: 'Trogir'
  },
  '2370326004': {
    ru: 'Турчьянске-Теплице',
    en: 'Turčianske Teplice'
  },
  '4016707001': {
    ru: 'Ужгород',
    en: 'Uzhhorod'
  },
  '5812839001': {
    ru: 'Велки-Осек',
    en: 'Velký Osek'
  },
  '6133164003': {
    ru: 'Винница',
    en: 'Vinnycja'
  },
  '3334999003': {
    ru: 'Водице',
    en: 'Vodice'
  },
  '6632040009': {
    ru: 'Волочиск',
    en: 'Volochysk'
  },
  '7133810004': {
    ru: 'Воловец',
    en: 'Volovets'
  },
  '3001552001': {
    ru: 'Вранов-над-Топлёу',
    en: 'Vranov nad Topľou'
  },
  '49584000': {
    ru: 'Врутки',
    en: 'Vrútky'
  },
  '5812839006': {
    ru: 'Вшетаты',
    en: 'Všetaty'
  },
  '4961571000': {
    ru: 'Вышков-на-Мораве',
    en: 'Vyškov na Moravě'
  },
  '372842002': {
    ru: 'Забржег-на-Мораве',
    en: 'Zábřeh na Moravě'
  },
  '307730007': {
    ru: 'Задар',
    en: 'Zadar'
  },
  '6632040011': {
    ru: 'Запорожье',
    en: 'Zaporizhzhia'
  },
  '5845172002': {
    ru: 'Ждяр-над-Сазавой',
    en: 'Žďár nad Sázavou'
  },
  '2624922001': {
    ru: 'Желетава',
    en: 'Želetava'
  },
  '17653000': {
    ru: 'Злин',
    en: 'Zlín'
  },
  '6632040014': {
    ru: 'Знаменка',
    en: 'Znamjanka'
  },
  '1742368001': {
    ru: 'Зноймо',
    en: 'Znojmo'
  },
  '10204001': {
    ru: 'Прага',
    en: 'Prague',
    cs: 'Praha'
  },
  '10204002': {
    ru: 'Брно',
    en: 'Brno',
    cs: 'Brno'
  },
  '10204003': {
    ru: 'Острава',
    en: 'Ostrava',
    cs: 'Ostrava'
  },
  '10204004': {
    ru: 'Пльзень',
    en: 'Pilsen',
    cs: 'Plzeň'
  },
  '10204005': {
    ru: 'Оломоуц',
    en: 'Olomouc',
    cs: 'Olomouc'
  },
  '10204006': {
    ru: 'Ческе-Будеёвице',
    en: 'České Budějovice',
    cs: 'České Budějovice'
  },
  '10204007': {
    ru: 'Пардубице',
    en: 'Pardubice'
  },
  '10204008': {
    ru: 'Градец-Кралове',
    en: 'Hradec Králové'
  },
  '10204009': {
    ru: 'Либерец',
    en: 'Liberec'
  },
  '10204010': {
    ru: 'Усти-над-Лабем',
    en: 'Ústí nad Labem'
  },
  '10204020': {
    ru: 'Братислава',
    en: 'Bratislava'
  },
  '10204021': {
    ru: 'Кошице',
    en: 'Košice'
  },
  '10204022': {
    ru: 'Жилина',
    en: 'Žilina'
  },
  '10204023': {
    ru: 'Попрад',
    en: 'Poprad'
  },
  '10204040': {
    ru: 'Вена',
    en: 'Vienna'
  },
  '10204041': {
    ru: 'Зальцбург',
    en: 'Salzburg'
  },
  '10204042': {
    ru: 'Грац',
    en: 'Graz'
  },
  '10204043': {
    ru: 'Линц',
    en: 'Linz'
  },
  '10204060': {
    ru: 'Будапешт',
    en: 'Budapest'
  },
  '10204061': {
    ru: 'Дебрецен',
    en: 'Debrecen'
  },
  '10204062': {
    ru: 'Мишкольц',
    en: 'Miskolc'
  },
  '10204080': {
    ru: 'Варшава',
    en: 'Warsaw'
  },
  '10204081': {
    ru: 'Краков',
    en: 'Krakow'
  },
  '10204082': {
    ru: 'Вроцлав',
    en: 'Wroclaw'
  },
  '10204083': {
    ru: 'Катовице',
    en: 'Katowice'
  },
  '10204100': {
    ru: 'Берлин',
    en: 'Berlin'
  },
  '10204101': {
    ru: 'Мюнхен',
    en: 'Munich'
  },
  '10204102': {
    ru: 'Дрезден',
    en: 'Dresden'
  },
  '10204103': {
    ru: 'Лейпциг',
    en: 'Leipzig'
  }
};

// Добавляем сообщения об ошибке для всех языков
const STATION_NOT_FOUND = {
  ru: 'Станция не найдена',
  en: 'Station not found',
  cs: 'Stanice nenalezena',
  pl: 'Stacja nie znaleziona',
  uk: 'Станцію не знайдено'
};

// Функция для получения названия станции с учетом языка
export const getStationName = (stationId, locale = 'ru') => {
  if (!stationId) return '';
  
  const station = STATION_NAMES[stationId];
  if (!station) {
    console.warn(`Translation not found for station ID: ${stationId}. Please add it to the stations list.`);
    return STATION_NOT_FOUND[locale] || STATION_NOT_FOUND.en;
  }
  
  return station[locale] || station.en || stationId;
};

// Функция для получения отсортированного списка всех станций
export const getAllStations = (lang = 'ru') => {
  return Object.entries(STATION_NAMES)
    .map(([id, names]) => ({
      id,
      name: names[lang] || names.en
    }))
    .sort((a, b) => 
      a.name.localeCompare(b.name, lang, {
        sensitivity: 'base',
        ignorePunctuation: true
      })
    );
};

// Функция для поиска станции по части названия
export const findStationsByName = (searchText, lang = 'ru') => {
  const searchLower = searchText.toLowerCase();
  return Object.entries(STATION_NAMES)
    .filter(([_, names]) => 
      names[lang]?.toLowerCase().includes(searchLower) || 
      names.en?.toLowerCase().includes(searchLower)
    )
    .map(([id, names]) => ({
      id,
      name: names[lang] || names.en
    }));
}; 