import React from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import App from './App';
import './index.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Добавляем проверку наличия ключа перед инициализацией
if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  console.error('Ошибка: Отсутствует публичный ключ Stripe (REACT_APP_STRIPE_PUBLISHABLE_KEY)!');
}

const options = {
  amount: 100, // Минимальная сумма в центах
  currency: 'eur',
  mode: 'payment',
  onError: (err) => {
    console.error('Ошибка Stripe:', err);
  }
};

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise} options={options}>
      <App />
    </Elements>
  </React.StrictMode>,
  document.getElementById('root')
);