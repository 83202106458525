import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

const CancelTicket = () => {
  const { ticketId } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const cancelTicket = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await fetch('/api/tickets/cancel', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ticketId,
            token
          })
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Failed to cancel ticket');
        }

        setSuccess(true);
        setTimeout(() => {
          navigate('/');
        }, 3000);

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (ticketId && token) {
      cancelTicket();
    }
  }, [ticketId, token, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <h1 className="text-2xl font-bold mb-4 text-center">Отмена билета</h1>
        
        {loading && (
          <div className="text-center">
            <p>Отменяем ваш билет...</p>
          </div>
        )}

        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded-md">
            {error}
          </div>
        )}

        {success && (
          <div className="bg-green-100 text-green-700 p-4 rounded-md">
            Билет успешно отменен. Возврат средств будет обработан в течение 5-7 рабочих дней.
          </div>
        )}
      </div>
    </div>
  );
};

export default CancelTicket; 