import React from 'react';
import { Users } from 'lucide-react';

const PassengerSelect = ({ value, onChange, t }) => {
  const getPassengerText = (num) => {
    if (t.passengers === t.passengers_plural) {
      // Для английского языка
      return `${num} ${num === 1 ? t.passengers : t.passengers_plural}`;
    } else {
      // Для русского языка
      if (num === 1) {
        return `${num} ${t.passengers}`;
      } else {
        return `${num} ${t.passengers_plural}`;
      }
    }
  };

  return (
    <div className="flex items-center bg-gray-100 p-2 rounded flex-grow">
      <Users className="text-gray-400 mr-2" />
      <select 
        className="bg-transparent flex-grow"
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
      >
        {[1, 2, 3, 4, 5].map(num => (
          <option key={num} value={num}>
            {getPassengerText(num)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PassengerSelect;