import React from 'react';
import { Clock, MapPin, Train, Users } from 'lucide-react';
import { getStationName } from '../utils/stations';

const RouteList = ({ routes = [], onRouteSelect, t }) => {
  // Форматирование времени
  const formatTime = (dateString) => {
    try {
      return new Date(dateString).toLocaleTimeString('ru-RU', {
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Error formatting time:', error);
      return '';
    }
  };

  // Форматирование длительности
  const formatDuration = (duration) => {
    if (!duration) return '00:00';
    try {
      // Сначала удаляем все существующие 'h' из строки
      const cleanDuration = duration.replace(/\s*h\s*/g, '').trim();
      // Затем добавляем один 'h' в конце
      return `${cleanDuration} h`;
    } catch (error) {
      console.error('Error formatting duration:', error);
      return '00:00';
    }
  };

  const RouteItem = ({ route }) => {
    // Добавляем маппинг станций
    const stationNames = {
      '3088864001': 'Прага',
      '3088864002': 'Острава',
      '3088864003': 'Брно',
      '3088864004': 'Пльзень',
      // Добавьте другие станции по необходимости
    };

    const getStationName = (stationId) => {
      return stationNames[stationId] || stationId;
    };

    return (
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <div className="flex justify-between items-start mb-6">
          <div className="flex-1">
            {/* Время отправления и прибытия */}
            <div className="flex items-center mb-3">
              <Clock className="text-[#9a55f4] mr-2" size={20} />
              <span className="font-semibold text-lg">
                {formatTime(route.departureTime)} - {formatTime(route.arrivalTime)}
              </span>
            </div>
            
            {/* Маршрут - обновляем отображение станций */}
            <div className="flex items-center text-gray-700">
              <MapPin className="text-[#9a55f4] mr-2" size={20} />
              <span>
                {getStationName(route.departureStationId)} → {getStationName(route.arrivalStationId)}
              </span>
            </div>
          </div>

          {/* Цена и тип транспорта */}
          <div className="text-right">
            <div className="flex items-center justify-end mb-2">
              <span className="font-bold text-lg">
                Цена: {route.price} {route.currency}
              </span>
            </div>
            <div className="flex items-center justify-end text-gray-600">
              <Train className="h-5 w-5" />
              <span className="ml-1">TRAIN</span>
            </div>
          </div>
        </div>

        {/* Дополнительная информация */}
        <div className="border-t pt-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center text-gray-600">
              <Users className="text-[#9a55f4] mr-2" size={16} />
              <span>Доступные места: {route.freeSeatsCount || route.availableSeats || 0}</span>
            </div>
            <div className="flex items-center text-gray-600">
              <Clock className="text-[#9a55f4] mr-2" size={16} />
              <span>{formatDuration(route.travelTime || route.duration)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-4">
      {routes.map((route, index) => (
        <div key={route.id || index} onClick={() => onRouteSelect(route)} className="cursor-pointer">
          <RouteItem route={route} />
        </div>
      ))}
    </div>
  );
};

export default RouteList;